import React, { useCallback, useEffect, useRef, useState } from "react";
import { ReactFlow } from "@xyflow/react";
import { useAtomValue } from "jotai";
import Toolbar from "../common/Toolbar";
import RightToolbar from "../common/RightToolbar";

import {
  handleNodeClick,
  onDragOver,
  onDropNode,
} from "../../helpers/canvasHelpers";

import {
  flowId,
  flowName,
  predictionDag,
  savedGenerationFlows,
  uid,
} from "../../state/state";
import { useAtom } from "jotai";
import stepsData, { nameIdMap } from "../../constants/categories";
import { nodeTypes, validConnections } from "./constants";


const GenerationFlow = ({
  nodes,
  edges,
  onNodesChange,
  onEdgesChange,
  onConnect,
  setNodes,
  setEdges,
}) => {
  const [selectedStep, setSelectedStep] = useState(null);
  const containerRef = useRef(null);
  const flowNameRef = useRef(null);
  const [isLoginPopupOpen, setLoginPopupOpen] = useState(false);
  const [rfInstance, setRfInstance] = useState(null);
  const [dag, setDag] = useAtom(predictionDag);
  const [preview, setPreview] = useState();
  const [showPreview, setShowPreview] = useState(false);
  const userId = useAtomValue(uid);

  const clearNodes = () => {
    setNodes([]);
    setEdges([]);
    setDag([]);
  };

  useEffect(() => {
    setLoginPopupOpen(true);
  }, []);

  const onDrop = useCallback(
    onDropNode(containerRef, stepsData, nodes, setNodes),
    [nodes][nodes],
  );

  return (
    <div
      style={{
        height: "89vh",
        display: "flex",
        flexDirection: "column",
        marginTop: "-17px",
        marginRight: "-6px",
        marginLeft: "-15px",
        marginBottom: "-20px",
      }}
    >
      <Toolbar
        setNodes={setNodes}
        setEdges={setEdges}
        rfInstance={rfInstance}
        setPreview={setPreview}
        setShowPreview={setShowPreview}
        showPreview={showPreview}
        flowType="generation"


      />
      <div
        ref={containerRef}
        onDrop={onDrop}
        onDragOver={onDragOver}
        style={{ flex: 1 }}
      >
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onNodeClick={handleNodeClick(stepsData, setSelectedStep)}
          proOptions={{ hideAttribution: true }}
          onInit={setRfInstance}
          nodeTypes={nodeTypes}
          fitView
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "#000000",
            borderRadius: "5px",
          }}
          isValidConnection={(connection) => {
            const source = nameIdMap.get(connection.source.slice(0, -2));
            const target = nameIdMap.get(connection.target.slice(0, -2));

            return validConnections[source].includes(target);
          }}
        />
      </div>
      <RightToolbar rfInstance={rfInstance} />

    </div>
  );
};

export default GenerationFlow;
