export const textCleaningOptionsList = [
  "Remove Special Characters",
  "Remove HTML Tags",
  "Remove Extra Whitespace",
  "Remove Stop Words",
  "Remove Numbers",
  "Remove Non-ASCII Characters",
  "Remove URLs",
  "Remove Emojis",
  "Remove Duplicate Lines",
];

export const formattingOptionsList = [
  "Convert to Lowercase",
  "Sentence Case",
  "Normalize Punctuation",
  "Add/Remove Line Breaks",
  "Replace Tabs with Spaces",
  "Trim Leading/Trailing Spaces",
  "Collapse Multiple Line Breaks into One",
  "Standardize Quotation Marks",
  "Normalize Spacing Around Punctuation",
];
