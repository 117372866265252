import { useEffect } from "react";
import { useAtom } from "jotai";
import CanvasCard from "../../common/CanvasCard";
import { BsGear } from "react-icons/bs";
import { knowledgeRetrievalConfigAtom } from "../../../state/state";

const Reranker = () => {
  const [config, setConfig] = useAtom(knowledgeRetrievalConfigAtom);

  useEffect(() => {
    const p = config.properties.ensemble;
    const updatedProps = { ...p, reranker: "true" };
    setConfig({ ...config, properties: { updatedProps } });
  }, []);

  return (
    <CanvasCard
      title="Reranker"
      headerIcon={<BsGear className="text-white text-2xl" />}
    ></CanvasCard>
  );
};

export default Reranker;
