import { useEdges, useNodeId } from "@xyflow/react";
import { useState, useEffect } from "react";

export const useConnected = () => {
  const edges = useEdges();
  const nodeId = useNodeId();
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    if (edges.some((el) => el.source === nodeId || el.target === nodeId))
      setConnected(true);
    else setConnected(false);
  }, [edges]);

  return connected;
};
