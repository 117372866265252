import { Card } from "antd";
import React, { useState, useEffect } from "react";
import { useAtom } from "jotai";
import { Handle, Position, useNodeId, useReactFlow } from "@xyflow/react";
import styles from "../styles.module.css";
import AddCard from "../../AddCard/AddCard";
import { nodeTitleMap } from "../../../constants/nodeTitles";
import { dataApi } from "../../../services/apiHelpers";
import { flowType } from "../../../state/state";
import { CardTitle } from "./Title";

const handleStyle = {
  position: "absolute",
  width: "30px",
  height: "30px",
};

const CanvasCard = ({
  title,
  headerIcon,
  className,
  extraTitleBarContent,
  children,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [nextNodeOptions, setNextNodeOptions] = useState([]);
  const id = useNodeId();
  const { addNodes, addEdges, getNodes } = useReactFlow();
  const [selectedFlow] = useAtom(flowType);

  const onAddNode = (nodeData) => {
    const nodeType = nodeTitleMap.get(nodeData.name) || "default";

    // Get the current node's position
    const currentNode = getNodes().find((node) => node.id === id);
    const currentNodePosition = currentNode?.position || { x: 0, y: 0 };

    const newNode = {
      id: `${nodeData.id}-${Date.now()}`,
      type: nodeType,
      position: {
        x: currentNodePosition.x + 600,
        y: currentNodePosition.y,
      },
      data: { label: nodeData.name },
    };

    addNodes(newNode);

    const newEdge = {
      id: `edge-${id}-${newNode.id}`,
      source: id,
      target: newNode.id,
      type: "default",
    };

    addEdges(newEdge);
  };

  const mapFlowType = (flow) => {
    const flowMapping = {
      "db-creation": "creation_flow",
      generation: "generation_flow",
    };
    return flowMapping[flow] || flow;
  };

  const titleBar = (
    <CardTitle
      title={title}
      isCollapsed={isCollapsed}
      setIsCollapsed={setIsCollapsed}
      headerIcon={headerIcon}
      extraContent={extraTitleBarContent}
    />
  );

  useEffect(() => {
    const fetchNextNodeOptions = async () => {
      try {
        const requestBody = {
          flow: mapFlowType(selectedFlow),
          node: title,
        };
        const response = await dataApi.post("/get_cards_mapping", requestBody);
        if (response?.data) {
          setNextNodeOptions(
            response.data.map((name, index) => ({
              id: `${index}`,
              name,
            })),
          );
        }
      } catch (error) {
        console.error("Error fetching next node options:", error);
      }
    };

    fetchNextNodeOptions();
  }, []);

  return (
    <div
      className={`${styles.card} ${isCollapsed ? styles.collapsedCard : ""}`}
      style={{
        position: "relative",
        transition: "width 0.3s ease",
      }}
    >
      {nextNodeOptions.length > 0 && (
        <div className="absolute top-2 -right-[2.5rem]">
          <AddCard newCardOptions={nextNodeOptions} onAddNode={onAddNode} />
        </div>
      )}
      <Handle type="target" position={Position.Left} style={handleStyle} />

      <Handle
        type="source"
        position={Position.Right}
        className={handleStyle}
        style={handleStyle}
      />
      <Card
        title={titleBar}
        className={className}
        style={{
          background: "#43413A",
          borderColor: "#43413A",
          maxWidth: isCollapsed ? "250px" : "500px",
          height: isCollapsed ? "50px" : "auto",
          padding: isCollapsed ? "0" : "20px",
          overflow: "hidden",
          transition: "all 0.3s ease",
        }}
      >
        {!isCollapsed && <div className="flex flex-col gap-4">{children}</div>}
      </Card>
    </div>
  );
};

export default CanvasCard;
