import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import "@xyflow/react/dist/style.css";

import Canvas from "./pages/Canvas";
import ApiDocs from "./pages/ApiDocs";
import ChatbotPage from "./pages/Chatbot";
import Login from "./components/Login/Login";
import Signup from "./components/Login/Signup";
import { Provider } from "jotai";
import { store } from "./state/state";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/development-studio" element={<Canvas />} />
          <Route path="/documentation" element={<ApiDocs />} />
          <Route path="/register" element={<Signup />} />
          <Route path="/chatbot" element={<ChatbotPage />} />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
