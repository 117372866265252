import { useEffect, useState } from "react";
import CanvasCard from "../../common/CanvasCard";
import { LuBrainCircuit } from "react-icons/lu";
import NumberInput from "../../common/NumberInput";
import { useAtom } from "jotai";
import { dbCreationDag } from "../../../state/state";
import { useNodeId, useReactFlow } from "@xyflow/react";
import { updateProperties } from "../../../helpers/flows";
import { useConnected } from "../../../hooks/useConnected";
import { FiEye } from "react-icons/fi";
import { handleSelectedPreview } from "../helpers";

const PHI = () => {
  const [threshold, setThreshold] = useState(0);
  const [dag, setDag] = useAtom(dbCreationDag);
  const nodeId = useNodeId();
  const connected = useConnected();
  const { getEdges } = useReactFlow();

  useEffect(() => {
    const node = {
      id: nodeId,
      node: "PHI",
      properties: { threshold: threshold },
    };
    if (connected)
      setDag((dag) => dag.filter((el) => el !== node).concat(node));
  }, [connected]);

  return (
    <CanvasCard
      title="PHI Redaction"
      bordered={false}
      headerIcon={<LuBrainCircuit className="text-white text-2xl" />}
      extraTitleBarContent={
        <FiEye
          className="text-white cursor-pointer"
          style={{ fontSize: "20px" }}
          onClick={handleSelectedPreview(getEdges(), nodeId)}
        />
      }
    >
      <NumberInput
        label="Threshold"
        description="PHI Threshold"
        onChange={(v) => {
          const update = updateProperties(nodeId, dag, setDag, connected);
          update({ threshold: v });
          setThreshold(v);
        }}
        defaultValue={0}
        min={0}
        max={100}
      />
    </CanvasCard>
  );
};

export default PHI;
