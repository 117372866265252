import {
  dbCreationDag,
  previewAtom,
  showPreviewAtom,
  store,
} from "../../../state/state";
import { error1, getPreview } from "../helpers";

export const updateProps =
  (nodeId, dbDag, setdbDag, connected, existingProps, setProps) => (props) => {
    const node = dbDag.find((el) => el.id === nodeId);
    const p = node?.properties;
    const newProps = p ? { ...p, ...props } : { ...existingProps, ...props };
    const val = { ...node, properties: newProps };

    if (connected) setdbDag(dbDag.filter((el) => el !== node).concat(val));
    else setProps(newProps);
  };

export const handleMethodChange =
  (setChunkingMethod, updateProperties) => (value) => {
    setChunkingMethod(value.toLowerCase());
    updateProperties({ chunking_method: value.toLowerCase() });
  };

export const handleDataChange =
  (setChunkingData, updateProperties) => (key, backendKey, value) => {
    setChunkingData((prev) => ({ ...prev, [key]: value }));
    updateProperties({ [backendKey]: value });
  };

const handleChunkingPreview = async (dag) => {
  const res = await getPreview(dag);
  if (res && res != error1) {
    store.set(previewAtom, {
      old: JSON.stringify(res.original_text.slice(0, 500), null, 2),
      new: JSON.stringify(res.processed_text.slice(0, 500), null, 2),
    });
  }
  store.set(showPreviewAtom, true);
};

export const handlePreview = (edges, nodeId) => (e) => {
  e.stopPropagation();

  const dbDag = store.get(dbCreationDag);
  const [, ...usefulEdges] = edges;

  console.log(dbDag);
  const getNewDag = () => usefulEdges.reduce(
    (acc, el) =>
      acc.some((f) => f?.id === nodeId)
        ? acc
        : acc.concat(dbDag.find((node) => node.id === el.source)),
    [],
  );
  const newDag = edges.length > 1 ? getNewDag() : dbDag;

  handleChunkingPreview(newDag);
};
