import React, { useEffect, useState } from "react";
import { dbCreationDag } from "../../../state/state";
import { Tabs } from "antd";
import { LuBrainCircuit } from "react-icons/lu";
import { useAtom } from "jotai";
import CanvasCard from "../../common/CanvasCard";
import { TabList } from "./options";
import DropdownInput from "../../common/DropdownInput";
import NumberInput from "../../common/NumberInput";
import TextInput from "../../common/TextInput";
import { useNodeId, useReactFlow } from "@xyflow/react";
import { useConnected } from "../../../hooks/useConnected";
import {
  handleDataChange,
  handleMethodChange,
  handlePreview,
  updateProps,
} from "./helpers";
import { FiEye } from "react-icons/fi";

const { TabPane } = Tabs;

const DataChunkingConfig = () => {
  const [chunkingMethod, setChunkingMethod] = useState("token-based");
  const [chunkingData, setChunkingData] = useState({
    maxChunkSize: 512,
    maxOverlap: 50,
    separator: ".",
    similarityThreshold: 0.7,
  });
  const [props, setProps] = useState({
    type: "sentence",
    chunk_size: chunkingData.maxChunkSize,
    overlap: chunkingData.maxOverlap,
    separator: chunkingData.separator,
    chunking_method: "token-based",
  });
  const [dbDag, setdbDag] = useAtom(dbCreationDag);
  const nodeId = useNodeId();
  const connected = useConnected();
  const { getEdges } = useReactFlow();

  const updateProperties = updateProps(
    nodeId,
    dbDag,
    setdbDag,
    connected,
    props,
    setProps,
  );
  const handleChunkingMethodChange = handleMethodChange(
    setChunkingMethod,
    updateProperties,
  );
  const handleChunkingDataChange = handleDataChange(
    setChunkingData,
    updateProperties,
  );

  const getDropdownHandler = (key) => {
    switch (key) {
      case "chunkingMethod":
        return (v) => handleChunkingMethodChange(v);
    }
  };

  const getDropdownVal = (key) => {
    switch (key) {
      case "chunkingMethod":
        return chunkingMethod;
    }
  };

  useEffect(() => {
    const val = {
      id: nodeId,
      node: "chunking",
      properties: props,
    };

    if (connected) setdbDag((dag) => [...dag, val]);
  }, [connected]);

  return (
    <CanvasCard
      title="Chunking Data"
      bordered={false}
      headerIcon={<LuBrainCircuit className="text-white text-2xl" />}
      extraTitleBarContent={
        <FiEye
          className="text-white cursor-pointer"
          style={{ fontSize: "20px" }}
          onClick={handlePreview(getEdges(), nodeId)}
        />
      }
    >
      <Tabs
        defaultActiveKey="1"
        className="config-tabs"
        onTabClick={(key) =>
          updateProperties({
            type:
              key === "1" ? "sentence" : key === "2" ? "paragraph" : "semantic",
          })
        }
      >
        {TabList.map((tab, ind) => (
          <TabPane tab={tab.title} key={ind + 1}>
            {tab.numberInputs?.map((item) => (
              <NumberInput
                label={item.label}
                description={item.helpText}
                onChange={(v) => {
                  handleChunkingDataChange(item.key, item.backend_key, v);
                }}
                key={item.label}
                defaultValue={item.default}
              />
            ))}
            {tab.textInputs?.map((item) => (
              <TextInput
                label={item.label}
                description={item.helpText}
                onChange={(e) => {
                  handleChunkingDataChange(
                    item.key,
                    item.backend_key,
                    e.currentTarget.value,
                  );
                }}
                key={item.label}
                defaultValue={item.default}
              />
            ))}
            {tab.dropdowns?.map((item) => (
              <DropdownInput
                title={item.label}
                options={item.inputTypes}
                selectedOption={getDropdownVal}
                handleMenuClick={getDropdownHandler(item.key)}
                key={item.label}
              />
            ))}
          </TabPane>
        ))}
      </Tabs>
    </CanvasCard>
  );
};

export default DataChunkingConfig;
