import { Input, Typography } from "antd";
import React, { useState } from "react";
import styles from "./styles.module.css";

const { Text } = Typography;

const TextInput = ({ onChange, label, description, defaultValue }) => {
  return (
    <div className="flex flex-col gap-3">
      <div className="text-white font-bold">{label}</div>
      <Input
        onChange={onChange}
        className={styles.input_number}
        defaultValue={defaultValue}
      />
      <Text className="text-gray-400 text-sm">{description}</Text>
    </div>
  );
};

export default TextInput;
