import React from "react";
import { Button, Tooltip } from "@mui/material";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";

const RightToolbar = ({ rfInstance }) => {
    const handleZoomIn = () => {
        if (rfInstance) {
            const currentZoom = rfInstance.getZoom();
            rfInstance.zoomTo(Math.min(currentZoom + 0.1, 2));
        }
    };

    const handleZoomOut = () => {
        if (rfInstance) {
            const currentZoom = rfInstance.getZoom();
            rfInstance.zoomTo(Math.max(currentZoom - 0.1, 0.5));
        }
    };

    const toolbarStyle = {
        position: "absolute",
        top: 85,
        right: 310,
        zIndex: 10,
        backgroundColor: "#43413A",
        padding: "5px",
        borderRadius: "5px",
        display: "flex",
        flexDirection: "row",
        gap: "10px",
        alignItems: "center",
    };

    const buttonStyle = {
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        padding: "5px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    };

    const iconStyle = {
        fontSize: "24px",
    };

    return (
        <div style={toolbarStyle}>
            <Tooltip title="Zoom In" arrow>
                <Button style={buttonStyle} onClick={handleZoomIn}>
                    <ZoomInIcon style={iconStyle} />
                </Button>
            </Tooltip>
            <Tooltip title="Zoom Out" arrow>
                <Button style={buttonStyle} onClick={handleZoomOut}>
                    <ZoomOutIcon style={iconStyle} />
                </Button>
            </Tooltip>
        </div>
    );
};

export default RightToolbar;
