import React, { useState } from 'react';
import { Button, Tooltip, TextField } from '@mui/material';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import EyeOutlined from '@mui/icons-material/Visibility';
import RestoreMenu from "../common/RestoreMenu";
import { handlePreview } from "../CreationFlow/helpers";
import { GrTemplate } from "react-icons/gr";
import { useAtom, useAtomValue } from "jotai";

import ResetFlow from "../common/ResetFlow";
import {
    FlowVariant,
    handleRestore,
    saveFlow,
} from "../../helpers/flows";
import {
    dbCreationDag,
    flowId,
    flowName,
    savedCreationFlows,
    uid,
} from "../../state/state";

const Toolbar = ({ setNodes, setEdges, rfInstance, setPreview, setShowPreview, showPreview, flowType = "creation" }) => {
    const [flows, setFlows] = useAtom(savedCreationFlows);
    const [_activeFlow, setActiveFlow] = useAtom(flowName);
    const [dag, setDag] = useAtom(dbCreationDag);
    const [fid, setFid] = useAtom(flowId);
    const [textFieldValue, setTextFieldValue] = useState("");
    const userId = useAtomValue(uid);
    const toolbarStyle = {
        position: "absolute",
        top: 85,
        left: 20,
        zIndex: 10,
        backgroundColor: "#43413A",
        padding: "2px",
        borderRadius: "5px",
        display: "flex",
        gap: "0",
        alignItems: "center",
    };

    const buttonStyle = {
        display: "flex",
        alignItems: "center",
        color: "white",
        justifyContent: "center",
        backgroundColor: "transparent",
        border: "none",
        padding: "0",
        margin: "0",
    };

    const separatorStyle = {
        color: "white",
        fontWeight: "bold",
    };
    const iconStyle = {
        margin: "0", 
        padding: "0", 
        fontSize: "19px",
    };

    return (
        <div style={toolbarStyle}>
            <TextField
                id="flow-name"
                label="Flow Name"
                variant="filled"
                size="small"
                value={textFieldValue}
                onChange={(e) => setTextFieldValue(e.target.value)}
                InputLabelProps={{
                    style: { color: "white" },
                }}
                InputProps={{
                    style: {
                        color: textFieldValue ? "black" : "white",
                        backgroundColor: "transparent",
                        transition: "background-color 0.3s ease",
                    },
                }}
                sx={{
                    "& .MuiFilledInput-root": {
                        backgroundColor: "transparent",
                    },
                    "& .MuiFilledInput-root:hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.1)",
                    },
                    "& .MuiFilledInput-root.Mui-focused": {
                        backgroundColor: "white",
                        color: "black",
                    },
                    "& .MuiFilledInput-underline:after": {
                        borderBottomColor: "white",
                    },
                    "& .MuiFilledInput-underline:before": {
                        borderBottomColor: "white",
                    },
                }}
            />
            <Tooltip title="Save Flow" arrow>
                <Button
                    style={buttonStyle}
                    onClick={saveFlow(
                        textFieldValue,
                        flows,
                        setFlows,
                        rfInstance,
                        setActiveFlow,
                        flowType,
                        FlowVariant.Flow
                    )}
                >
                    <SaveAltIcon style={iconStyle} />
                </Button>
            </Tooltip>

            <RestoreMenu
                handleRestore={handleRestore(
                    setNodes,
                    setEdges,
                    setActiveFlow,
                )}
                setTextFieldValue={setTextFieldValue}
                flowType={flowType}
                flowVariant={FlowVariant.Flow}
            />

            <ResetFlow setDag={setDag} setNodes={setNodes} setEdges={setEdges} />

            <span style={separatorStyle}>|</span>


            <Tooltip title="Save Flow as Template" arrow>
                <Button
                    style={buttonStyle}
                    onClick={saveFlow(
                        textFieldValue,
                        flows,
                        setFlows,
                        rfInstance,
                        setActiveFlow,
                        flowType,
                        FlowVariant.Template
                    )}
                >
                    <GrTemplate style={iconStyle} />
                </Button>
            </Tooltip>



            <RestoreMenu
                handleRestore={handleRestore(
                    setNodes,
                    setEdges,
                    setActiveFlow,
                    setFid,
                )}
                setNodes={setNodes}
                setEdges={setEdges}
                setActiveFlow={setActiveFlow}
                setFid={setFid}
                setTextFieldValue={setTextFieldValue}
                flowType={flowType}
                flowVariant={FlowVariant.Template}
            />

            <RestoreMenu
                handleRestore={handleRestore(
                    setNodes,
                    setEdges,
                    setActiveFlow,
                    setFid,
                )}
                setNodes={setNodes}
                setEdges={setEdges}
                setActiveFlow={setActiveFlow}
                setFid={setFid}
                setTextFieldValue={setTextFieldValue}
                flowType={flowType}
                flowVariant={FlowVariant.GlobalTemplates}
            />

            <span style={separatorStyle}>|</span>

            <Tooltip title="Show Text Preview" arrow>
                <Button
                    style={buttonStyle}
                    onClick={async () => {
                        handlePreview(
                            dag,
                            fid,
                            userId,
                            setPreview,
                            showPreview,
                            setShowPreview,
                        );
                    }}
                >
                    <EyeOutlined style={iconStyle} />
                </Button>
            </Tooltip>
        </div>
    );
};

export default Toolbar;
