const SentenceTab = {
  title: "Sentence",
  numberInputs: [
    {
      label: "Max Chunk Size (characters/tokens)",
      key: "maxChunkSize",
      backend_key: "chunk_size",
      helpText: "Max size per chunk, e.g., 512 tokens or characters.",
      default: 512,
    },
    {
      label: "Max Sentence Overlap",
      key: "maxOverlap",
      backend_key: "overlap",
      helpText: "Overlap in sentences between consecutive chunks.",
      default: 50,
    },
  ],
  textInputs: [
    {
      label: "Separator",
      key: "separator",
      backend_key: "separator",
      helpText: "Separator used for chunking sentences (e.g., '.', '?', '!').",
      default: ".",
    },
  ],
  dropdowns: [
    {
      label: "Chunking Method",
      key: "chunkingMethod",
      backend_key: "chunking_method",
      helpText: "Select chunking method (token-based or character-based).",
      inputTypes: ["Token-based", "Character-based"],
    },
  ],
};

const ParagraphTab = {
  title: "Paragraph",
  numberInputs: [
    {
      label: "Max Chunk Size (characters/tokens)",
      key: "maxChunkSize",
      backend_key: "chunk_size",
      helpText: "Max size per chunk, e.g., 1000 characters.",
    },
    {
      label: "Max Paragraph Overlap",
      key: "maxOverlap",
      backend_key: "overlap",
      helpText: "Overlap in paragraphs between consecutive chunks.",
    },
  ],
  textInputs: [
    {
      label: "Separator",
      key: "separator",
      backend_key: "separator",
      helpText:
        "Separator used for chunking paragraphs (e.g., '\\n\\n' or custom).",
      default: ".",
    },
  ],
  dropdowns: [],
};

const SemanticTab = {
  title: "Semantic",
  numberInputs: [
    {
      label: "Max Chunk Size (tokens/characters)",
      key: "maxChunkSize",
      backend_key: "chunk_size",
      helpText: "Max size per chunk, e.g., 512 tokens or 2000 characters.",
    },
    {
      label: "Chunk Overlap",
      key: "maxOverlap",
      backend_key: "overlap",
      helpText: "Overlap in tokens between consecutive chunks.",
    },
    {
      label: "Similarity Threshold",
      backend_key: "similarity",
      key: "similarityThreshold",
      helpText: "Threshold for semantic similarity when grouping sentences.",
    },
  ],
  dropdowns: [],
};

export const TabList = [SentenceTab, ParagraphTab, SemanticTab];
