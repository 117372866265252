import { nodeTitleMap } from "../constants/nodeTitles";

export const onDropNode =
  (containerRef, stepsData, nodes, setNodes) => (event) => {
    event.preventDefault();
    const stepId = event.dataTransfer.getData("application/reactflow");
    if (stepId && containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const dropX = event.clientX - containerRect.left;
      const dropY = event.clientY - containerRect.top;

      const foundStep = stepsData
        .flatMap((category) => category.steps)
        .find((s) => s.id === stepId);
      const fullName = foundStep ? foundStep.name : stepId;

      const newNode = {
        id: `${stepId}-${nodes.length}`,
        type: nodeTitleMap.get(fullName) ?? "default",
        position: { x: dropX, y: dropY },
        data: { label: fullName },
      };

      setNodes((nds) => [...nds, newNode]);
    }
  };

export const onDragOver = (event) => {
  event.preventDefault();
  event.dataTransfer.dropEffect = "move";
};

export const handleNodeClick =
  (stepsData, setSelectedStep) => (_event, node) => {
    const stepId = node.data.label;
    const step = stepsData
      .flatMap((category) => category.steps)
      .find((s) => s.name === stepId);
    if (step) {
      setSelectedStep(step);
    }
  };
