// Import the necessary icons from react-icons
import { LuBrainCircuit } from "react-icons/lu";
import {
  FaUpload, // Upload
  FaTools, // Tool
  FaChartBar, // Bar Chart
  FaSearch, // Search
  FaLock, // Lock
  FaEyeSlash, // Eye Invisible
  FaEdit, // Edit
  FaDesktop,
  FaDatabase, // Database
  FaBroom,
} from "react-icons/fa";
import { GoDatabase } from "react-icons/go";
import { CiPlay1 } from "react-icons/ci";
import { BsExclamationCircle } from "react-icons/bs";

// Original stepsData array
const stepsData = [
  {
    category: "Database",
    categoryIcon: <GoDatabase />,

    steps: [
      {
        id: "selectDatabase",
        name: "Select Database",
        description: "Select the database",
        icon: <GoDatabase />,
        flow: "generation",
      },
    ],
  },
  {
    category: "Data Ingestion",
    categoryIcon: <GoDatabase />,

    steps: [
      {
        id: "upload",
        name: "Upload Data",
        description: "Select the file type to upload data",
        icon: <FaUpload />,
        flow: "db-creation",
      },
      {
        id: "preprocess",
        name: "Data Preprocessing",
        description: "Select the data preprocessing method",
        icon: <FaTools />,
        flow: "db-creation",
      },
      {
        id: "phi",
        name: "PHI Redaction",
        description: "Redact personally identifiable information",
        icon: <FaBroom />,
        flow: "db-creation",
      },
    ],
  },
  {
    category: "Knowledge",
    categoryIcon: <GoDatabase />,

    steps: [
      {
        id: "chunk",
        name: "Chunking Data",
        description: "Select the data chunking method",
        icon: <FaChartBar />,
        flow: "db-creation",
      },
      {
        id: "db",
        name: "Vector Database Creation",
        description: "Select the database",
        icon: <FaDatabase />,
        flow: "db-creation",
      },
      {
        id: "graph",
        name: "Knowledge Graph Construction",
        description: "Construct a knowledge graph",
        icon: <FaLock />, // Keep Lock for Graph construction
        flow: "db-creation",
      },
      {
        id: "retrieve",
        name: "Knowledge Retrieving",
        description: "Retrieve knowledge from the graph",
        icon: <FaSearch />,
        flow: "generation",
      },
      {
        id: "HypotheticalQueryGeneration ",
        name: "Hypothetical Query Generation",
        description: "Retrieve knowledge from the graph",
        icon: <FaSearch />,
        flow: "db-creation",
      },
      {
        id: "hybrid-retrieval",
        icon: <FaSearch />,
        name: "Hybrid Retrieval Configuration",
        flow: "generation",
        description: "Retrieve knowledge from the graph",
      },
    ],
  },
  {
    category: "Intelligence",
    categoryIcon: <GoDatabase />,

    steps: [
      {
        id: "big-models",
        name: "Big LLMs",
        description: "Select a big LLM model",
        icon: <LuBrainCircuit />,
        flow: "generation",
      },
      // {
      //   id: "medium-models",
      //   name: "Medium LLM Models",
      //   description: "Select a medium LLM model",
      //   icon: <GiArtificialIntelligence />,
      //   flow: "generation",
      // },
      // {
      //   id: "small-models",
      //   name: "Small LLM Models",
      //   description: "Select a small LLM model",
      //   icon: <GiArtificialIntelligence />,
      //   flow: "generation",
      // },
    ],
  },
  {
    category: "Test",
    categoryIcon: <GoDatabase />,

    steps: [
      {
        id: "testFlow",
        name: "Test Flow",
        description: "Test the flow",
        icon: <CiPlay1 />,
        flow: "generation",
      },
      {
        id: "generateEndpoint",
        name: "Generate Authorization Token",
        description: "Generate the authorization token",
        icon: <CiPlay1 />,
        flow: "generation",
      },
    ],
  },
  {
    category: "Boosting Tools",
    categoryIcon: <GoDatabase />,

    steps: [
      {
        id: "hallucination-shield",
        name: "Hallucination Shield",
        description: "Shield against hallucinations",
        icon: <FaDesktop />,
        flow: "generation",
      },
      {
        id: "query-normalization",
        name: "Query Normalization",
        description: "Normalize queries",
        icon: <FaLock />, // Keep Lock for Normalization
        flow: "generation",
      },
      {
        id: "answer-refinement",
        name: "Answer Refinement",
        description: "Refine generated answers",
        icon: <FaEdit />,
        flow: "generation",
      },
      {
        id: "post-gen-shield",
        name: "Post Generation Hallucination Shield",
        description: "Shield against post-generation hallucinations",
        icon: <FaEyeSlash />,
        flow: "generation",
      },
      {
        id: "reranker",
        name: "Reranker",
        description: "Rerank results based on criteria",
        icon: <FaLock />, // Keep Lock for Reranking
        flow: "generation",
      },
    ],
  },
  {
    category: "Stability & Monitoring",
    categoryIcon: <GoDatabase />,

    steps: [
      {
        id: "error-handling",
        name: "Error Handling",
        description: "Handle errors gracefully",
        icon: <BsExclamationCircle className="icon" />,
        flow: "generation",
      },
      {
        id: "realtime-analysis",
        name: "Realtime Analysis",
        description: "Analyze data in real-time",
        icon: <FaChartBar />,
        flow: "generation",
      },
    ],
  },
];

// Create a new array to store categories and steps with icons
const stepsCategories = stepsData.map((categoryData) => ({
  category: categoryData.category,
  categoryIcon: categoryData.categoryIcon,
  steps: categoryData.steps.map((step) => ({
    id: step.id,
    name: step.name,
    icon: step.icon,
    flow: step.flow,
  })),
}));

export const nameIdMap = new Map(
  stepsData.flatMap((category) =>
    category.steps.map((step) => [step.id, step.name])
  )
);

// Export the new array
export default stepsCategories;
