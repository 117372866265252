import React, { useEffect, useState } from "react";
import { InputNumber, Dropdown, Menu, Typography, Tabs } from "antd";
import { FiChevronDown } from "react-icons/fi";
import { LuBrainCircuit } from "react-icons/lu";
import CanvasCard from "../../common/CanvasCard";
import { knowledgeRetrievalConfigAtom } from "../../../state/state";
import { useNodeId } from "@xyflow/react";
import { useAtom } from "jotai";

const { Text } = Typography;
const { TabPane } = Tabs;

const KnowledgeRetrieving = () => {
  const [llmModel, setLlmModel] = useState("GPT 4o mini");
  const [retrievalContext, setRetrievalContext] = useState({
    textUnitProp: 0.5,
    communityProp: 0.1,
    maxTurns: 5,
    userTurnsOnly: true,
    topKMappedEntities: 10,
    topKRelationships: 10,
    includeEntityRank: true,
    includeRelationshipWeight: true,
    includeCommunityRank: false,
    maxTokens: 3000,
  });
  const [config, setConfig] = useAtom(knowledgeRetrievalConfigAtom);
  const nodeId = useNodeId();

  const handleLlmModelChange = (key) => {
    setLlmModel(key);
  };

  const handleRetrievalContextChange = (key, value) => {
    setRetrievalContext((prev) => ({ ...prev, [key]: value }));
  };

  useEffect(() => {
    setConfig({
      nodeId,
      llmModel,
      retrievalContext,
    });
  }, [llmModel, retrievalContext, nodeId, setConfig]);

  const llmMenu = (
    <Menu onClick={({ key }) => handleLlmModelChange(key)}>
      <Menu.Item key="GPT 4o mini">GPT 4o mini</Menu.Item>
      <Menu.Item key="Llama 8B">Llama 8B</Menu.Item>
    </Menu>
  );

  return (
    <CanvasCard
      title="Knowledge Retrieving"
      headerIcon={<LuBrainCircuit className="text-white text-2xl" />}
      className="config-card"
    >
      <div className="flex flex-col gap-4">
        <Tabs defaultActiveKey="1" className="config-tabs">
          <TabPane tab="Entities & Embeddings" key="1">
            <div className="input-section">
              <div className="option-label">LLM Model</div>
              <Dropdown overlay={llmMenu} trigger={["click"]}>
                <div className="dropdown-button">
                  <span>{llmModel}</span>
                  <FiChevronDown className="dropdown-icon" />
                </div>
              </Dropdown>
              <Text className="help-text">
                Select LLM for entity and relationship extraction.
              </Text>
            </div>
          </TabPane>

          <TabPane tab="Retrieval" key="2">
            <div className="input-section">
              <div className="option-label">Text Unit Prop</div>
              <InputNumber
                min={0}
                max={1}
                step={0.01}
                value={retrievalContext.textUnitProp}
                onChange={(value) =>
                  handleRetrievalContextChange("textUnitProp", value)
                }
                className="input-number"
              />
            </div>

            <div className="input-section">
              <div className="option-label">Community Prop</div>
              <InputNumber
                min={0}
                max={1}
                step={0.01}
                value={retrievalContext.communityProp}
                onChange={(value) =>
                  handleRetrievalContextChange("communityProp", value)
                }
                className="input-number"
              />
            </div>

            <div className="input-section">
              <div className="option-label">Max Turns</div>
              <InputNumber
                min={1}
                max={20}
                value={retrievalContext.maxTurns}
                onChange={(value) =>
                  handleRetrievalContextChange("maxTurns", value)
                }
                className="input-number"
              />
            </div>

            <div className="input-section">
              <div className="option-label">Include Entity Rank</div>
              <InputNumber
                min={0}
                max={1}
                value={retrievalContext.includeEntityRank ? 1 : 0}
                onChange={(value) =>
                  handleRetrievalContextChange(
                    "includeEntityRank",
                    Boolean(value)
                  )
                }
                className="input-number"
              />
            </div>
          </TabPane>
        </Tabs>
      </div>
    </CanvasCard>
  );
};

export default KnowledgeRetrieving;
