import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Divider, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import styles from "./styles.module.css";

const Sidebar = ({ isCollapsed, onToggle }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const flowId = searchParams.get("flowId");

  const currentFlowName = localStorage.getItem("currentFlowName");

  return (
    <div
      className={`${styles.sidebar} ${
        isCollapsed ? styles.sidebarCollapsed : styles.sidebarExpanded
      }`}
    >
      {/* Top Section with Toggle Icon */}
      <div
        className={`${styles.topSection} ${
          isCollapsed ? styles.topSectionCollapsed : styles.topSectionExpanded
        }`}
      >
        {isCollapsed ? (
          <MenuIcon
            sx={{ color: "black", cursor: "pointer" }}
            onClick={onToggle}
          />
        ) : (
          <MenuOpenIcon
            sx={{ color: "black", cursor: "pointer" }}
            onClick={onToggle}
          />
        )}
      </div>
      {!isCollapsed && (
        <>
          <br />
          <br />
          <br />
        </>
      )}
      <br />
      <div className={styles.logo}>
        {/* Collapsed/Expanded Image */}
        {isCollapsed ? (
          <img
            src="/Images/ia-logo-icon-black.png"
            alt="ia"
            width="50"
            height="50"
            onClick={() => navigate("/")}
          />
        ) : (
          <img
            src="/Images/ia-logo-no-bg.png"
            alt="ia"
            width="240"
            height="200"
            onClick={() => navigate("/")}
          />
        )}
      </div>

      {!isCollapsed && (
        <>
          <br />
          <Divider orientation="horizontal" flexItem />
          <br />
          <Typography
            variant="subtitle2"
            sx={{ color: "black", textAlign: "center" }}
          >
            {!currentFlowName ? flowId : currentFlowName}
          </Typography>
        </>
      )}
    </div>
  );
};

export default Sidebar;
