import React, { useState } from "react";
import { Tooltip, Button, Modal, Box, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import { dataApi } from "../../services/apiHelpers";
import { useAtom, useAtomValue } from "jotai";
import { flowId, uid } from "../../state/state";
import { notification } from "antd";
import {
  FlowVariant,
  getFlowIdFromName,
  getRoutes,
} from "../../helpers/flows";

const RestoreMenu = ({
  flowType,
  setTextFieldValue,
  flowVariant,
  handleRestore
}) => {
  const [anchorEl, _setAnchorEl] = useState(null);
  const [flows, setFlows] = useState({});
  const [filteredFlows, setFilteredFlows] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [_fid, setFid] = useAtom(flowId);
  const userId = useAtomValue(uid);
  const open = Boolean(anchorEl);
  const { getRoute, title, icon } = getRoutes(flowVariant);

  const getPayload = () => {
    switch (flowVariant) {
      case FlowVariant.GlobalTemplates:
        return { flow: flowType === "creation" ? "creation_flow" : "generation_flow" };
      default:
        return { user_id: userId, flow_id: "" };
    }
  };

  const getUsableFlows = (entries) => {
    if (flowVariant === FlowVariant.GlobalTemplates)
      return entries.map((el) => el[0]);
    else
      return entries
        .filter((el) => el[1].flow_object.flowType === flowType)
        .map((x) => x[1].flow_name);
  };

  const getFlows = async () => {
    try {
      const res = await dataApi.post(getRoute, getPayload());
      const flows = res.data;
      setFlows(flows);
      const entries = Object.entries(flows);
      const usableFlows = getUsableFlows(entries);
      setFilteredFlows(usableFlows);
    } catch (e) {
      notification.error({
        message: `Could not fetch existing ${flowVariant === FlowVariant.Flow ? "flows" : "templates"
          }`,
        description: "Something went wrong.",
      });
    }
  };

  const handleDeleteFlow = async (flowName) => {
    const flowId = await getFlowIdFromName(flowName, flowVariant);
    try {
      await dataApi.post("/delete_object", {
        user_id: userId,
        flow_id: flowId,
      });
      setFilteredFlows((prev) => prev.filter((flow) => flow !== flowName));
      notification.success({
        message: "Flow deleted",
        description: `${flowName} has been successfully deleted.`,
      });
    } catch (error) {
      notification.error({
        message: "Delete failed",
        description: `Could not delete ${flowName}. Please try again.`,
      });
    }
  };

  const handleOpenModal = () => {
    getFlows();
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <Tooltip title={title} arrow>
        <Button
          id="demo-customized-button"
          aria-controls={open ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          disableElevation
          onClick={handleOpenModal}
          color="white"
          style={{
            width: "100%",
            height: "100%",
            fontSize: "19px",
            color: "white",
          }}
        >
          {icon}
        </Button>
      </Tooltip>

      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <div className="flex justify-between items-center mb-4">
            <h2>Restore Flows</h2>
            <IconButton onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </div>
          {filteredFlows.map((flow) => (
            <div
              key={flow}
              className="flex justify-between items-center p-2 border-b"
            >
              <span
                className="cursor-pointer text-blue-600"
                onClick={async () => {
                  await handleRestore(
                    flow,
                    flows,
                    flowVariant,
                    setTextFieldValue,
                    setFid
                  );
                  handleCloseModal();
                }}
              >
                {flow}
              </span>
              <DeleteIcon
                className="text-red-500 cursor-pointer"
                onClick={() => handleDeleteFlow(flow)}
              />
            </div>
          ))}
        </Box>
      </Modal>
    </div>
  );
};

export default RestoreMenu;
