import React, { useState, useEffect } from "react";
import { Input, Dropdown, Menu, InputNumber, Typography, Tabs } from "antd";
import { FiChevronDown } from "react-icons/fi";
import { LuBrainCircuit } from "react-icons/lu";
import { useAtom } from "jotai";
import { defaultGenerationPrompt } from "../../../constants";
import { bigModelConfigAtom } from "../../../state/state";
import CanvasCard from "../../common/CanvasCard";

const { Text } = Typography;
const { TabPane } = Tabs;
const { TextArea } = Input;

const BigLLMs = () => {
  const [modelName, setModelName] = useState("gpt_4o");
  const [temperature, setTemperature] = useState(0.7);
  const [maxTokens, setMaxTokens] = useState(1000);
  const [topP, setTopP] = useState(0.9);
  const [frequencyPenalty, setFrequencyPenalty] = useState(0.0);
  const [presencePenalty, setPresencePenalty] = useState(0.0);
  const [stopSequences, setStopSequences] = useState("");
  const [promptTemplate, setPromptTemplate] = useState(defaultGenerationPrompt);
  const [loading, setLoading] = useState(false);

  const [, setBigModelConfig] = useAtom(bigModelConfigAtom);

  useEffect(() => {
    const config = {
      modelName,
      temperature,
      maxTokens,
      topP,
      frequencyPenalty,
      presencePenalty,
      stopSequences,
      promptTemplate,
    };
    setBigModelConfig(config);
  }, [
    modelName,
    temperature,
    maxTokens,
    topP,
    frequencyPenalty,
    presencePenalty,
    stopSequences,
    promptTemplate,
    setBigModelConfig,
  ]);

  const handleModelChange = (key) => {
    setModelName(key);
  };

  const modelMenu = (
    <Menu onClick={({ key }) => handleModelChange(key)}>
      <Menu.Item key="Llama-80B" className="dropdown-menu-item">
        Llama-80B
      </Menu.Item>
      <Menu.Item key="GPT 4o" className="dropdown-menu-item">
        GPT 4o
      </Menu.Item>
      <Menu.Item key="Claude" className="dropdown-menu-item">
        Claude
      </Menu.Item>
    </Menu>
  );

  return (
    <CanvasCard
      title="Big LLMs"
      headerIcon={<LuBrainCircuit className="text-white text-2xl" />}
      className="config-card"
    >
      <Tabs defaultActiveKey="1" className="config-tabs">
        <TabPane tab="General Settings" key="1">
          <div className="input-section">
            <div className="option-label">Model Name</div>
            <Dropdown overlay={modelMenu} trigger={["click"]}>
              <div className="dropdown-button">
                <span>{modelName}</span>
                <FiChevronDown className="dropdown-icon" />
              </div>
            </Dropdown>
          </div>

          <div className="input-section">
            <div className="option-label">Temperature</div>
            <InputNumber
              min={0}
              max={1}
              step={0.01}
              value={temperature}
              onChange={(value) => setTemperature(value)}
              className="input-number"
            />
            <Text className="help-text">
              Controls the randomness of the output (0.0 for deterministic, 1.0
              for more random).
            </Text>
          </div>

          <div className="input-section">
            <div className="option-label">Max Tokens</div>
            <InputNumber
              min={1}
              max={4096}
              value={maxTokens}
              onChange={(value) => setMaxTokens(value)}
              className="input-number"
            />
            <Text className="help-text">
              The maximum number of tokens in the response.
            </Text>
          </div>

          <div className="input-section">
            <div className="option-label">Top P</div>
            <InputNumber
              min={0}
              max={1}
              step={0.01}
              value={topP}
              onChange={(value) => setTopP(value)}
              className="input-number"
            />
            <Text className="help-text">
              Controls nucleus sampling (smaller set of tokens with a
              probability mass of top_p).
            </Text>
          </div>

          <div className="input-section">
            <div className="option-label">Prompt Template</div>
            <TextArea
              value={promptTemplate}
              onChange={(e) => setPromptTemplate(e.target.value)}
              className="input-field"
              autoSize={{ minRows: 3, maxRows: 6 }}
            />
            <Text className="help-text">
              The template used to format the input query for the LLM.
            </Text>
          </div>
        </TabPane>

        <TabPane tab="Advanced Settings" key="2">
          <div className="input-section">
            <div className="option-label">Frequency Penalty</div>
            <InputNumber
              min={0}
              max={1}
              step={0.01}
              value={frequencyPenalty}
              onChange={(value) => setFrequencyPenalty(value)}
              className="input-number"
            />
            <Text className="help-text">
              Penalizes repeated tokens, encouraging diversity.
            </Text>
          </div>

          <div className="input-section">
            <div className="option-label">Presence Penalty</div>
            <InputNumber
              min={0}
              max={1}
              step={0.01}
              value={presencePenalty}
              onChange={(value) => setPresencePenalty(value)}
              className="input-number"
            />
            <Text className="help-text">
              Penalizes tokens that have already appeared, encouraging new
              topics.
            </Text>
          </div>

          <div className="input-section">
            <div className="option-label">Stop Sequences</div>
            <Input
              value={stopSequences}
              onChange={(e) => setStopSequences(e.target.value)}
              className="input-field"
            />
            <Text className="help-text">
              A list of tokens that will stop the model from generating further
              output.
            </Text>
          </div>

          <div className="input-section">
            <div className="option-label">Prompt Template</div>
            <TextArea
              value={promptTemplate}
              onChange={(e) => setPromptTemplate(e.target.value)}
              className="input-field"
              autoSize={{ minRows: 3, maxRows: 6 }}
            />
            <Text className="help-text">
              The template used to format the input query for the LLM.
            </Text>
          </div>
        </TabPane>
      </Tabs>
    </CanvasCard>
  );
};

export default BigLLMs;
