import React, { useState, useEffect } from "react";
import { useAtom } from "jotai";
import {
  Snackbar,
  Alert as MuiAlert,
  Avatar,
  Button,
  TextField,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import { uid, flowId } from "../../state/state";
import { predictionApi } from "../../services/apiHelpers";
import Sidebar from "./Sidebar";

import styles from "./styles.module.css";

const ChatbotComponent = () => {
  const [messages, setMessages] = useState([
    { sender: "bot", content: "Hi, how can I help you?" },
  ]);
  const [inputValue, setInputValue] = useState("");
  const [userId] = useAtom(uid);
  const [currentFlowId] = useAtom(flowId);
  const [dag, setDag] = useState(null);
  const [loading, setLoading] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error"); // 'error', 'success', 'info', 'warning'

  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleSidebar = () => {
    setIsCollapsed((prev) => !prev);
  };

  useEffect(() => {
    const savedDag = localStorage.getItem("chatbotDag");
    if (savedDag) {
      setDag(JSON.parse(savedDag));
    } else {
      showNotification(
        "Missing DAG",
        "No DAG found in local storage. Please start a new chat."
      );
    }
  }, []);

  const showNotification = (title, message, severity = "error") => {
    setSnackbarMessage(`${title}: ${message}`);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSendMessage = async () => {
    if (!inputValue.trim()) {
      showNotification(
        "Empty Message",
        "Please enter a message before sending."
      );
      return;
    }

    const userMessage = {
      sender: "user",
      content: inputValue,
    };

    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setInputValue("");
    setLoading(true);

    const requestBody = {
      dag,
      uid: userId,
      flow_id: currentFlowId,
      query: inputValue,
    };

    try {
      const response = await predictionApi.post("/predict", requestBody);
      const botResponse = response.data || "No response received";
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "bot", content: botResponse },
      ]);
      setLoading(false);
    } catch (error) {
      console.error("API error:", error.response || error);
      showNotification(
        "Error Fetching Response",
        error.response?.data?.message || "An error occurred on the server."
      );
      setLoading(false);
    }
  };

  return (
    <Box
      bgcolor="white"
      sx={{
        display: "flex",
        alignItems: "center",
        minHeight: "100vh",
        width: "100%",
      }}
    >
      <div id="sidebar">
        <Box
          sx={{
            backgroundColor: "#efefef",
            display: "flex",
            alignItems: "start",
            minHeight: "100vh",
            height: "100vh",
          }}
        >
          <Sidebar isCollapsed={isCollapsed} onToggle={toggleSidebar} />
        </Box>
      </div>

      <div id="chatbot" className="w-full">
        <Box
          className="w-full flex flex-col p-[15px]"
          display="flex"
          flexDirection="column"
          bgcolor="white"
          sx={{
            display: "flex",
            alignItems: "center",
            minHeight: "100vh",
            width: "100%",
          }}
        >
          {/* Header */}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            p={2}
            border="1px solid #ddd"
            sx={{
              width: "100%",
              backgroundColor: "#F2F9B7",
              color: "black",
            }}
          >
            <Typography variant="subtitle2">
              Inference AI Dev Studio - Test Portal
            </Typography>
          </Box>

          {/* Chat History */}
          <Box className={styles.chatHistory} flex={1} overflow="auto" p={2}>
            {messages.map((message, index) => (
              <Box
                key={index}
                display="flex"
                alignItems="flex-start"
                mb={2}
                className={
                  message.sender === "user"
                    ? styles.messageUser
                    : styles.messageBot
                }
                sx={{ borderRadius: "10px", p: "12px" }}
              >
                <Avatar
                  sx={{
                    bgcolor: message.sender === "user" ? "#F2F9B7" : "#43413A",
                    color: message.sender === "user" ? "black" : "white",
                  }}
                >
                  {message.sender === "user" ? "U" : "B"}
                </Avatar>
                <Box ml={2}>
                  <Typography variant="subtitle2">
                    {message.sender === "user" ? "You" : "Bot"}
                  </Typography>
                  <Typography variant="body2">{message.content}</Typography>
                </Box>
              </Box>
            ))}
          </Box>

          {/* Input Area */}
          <Box
            display="flex"
            alignItems="center"
            p={2}
            borderTop="1px solid #ddd"
            sx={{ width: "100%" }}
          >
            <TextField
              multiline
              fullWidth
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder="Type your message here..."
              variant="outlined"
              size="small"
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  handleSendMessage();
                }
              }}
            />
            <Box ml={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSendMessage}
                disabled={loading}
                type="submit"
                sx={{ backgroundColor: "#43413a" }}
              >
                {loading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  "Send"
                )}
              </Button>
            </Box>
          </Box>
        </Box>
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MuiAlert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
          elevation={6}
          variant="filled"
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default ChatbotComponent;
