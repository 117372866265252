import React, { useState } from "react";
import { Button, Modal } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { Tooltip } from "@mui/material";

const ResetFlow = ({ setDag, setNodes, setEdges }) => {
    const [open, setOpen] = useState(false);

    const handleConfirm = () => {
        setNodes([]);
        setEdges([]);
        setDag([]);
        setOpen(false);
    };

    return (
        <>
            <Tooltip title="Reset Flow" arrow>
              <Button style={{ color: "#fff", backgroundColor: "transparent", border: "none" }} onClick={() => setOpen(true)}>
                <DeleteOutlined style={{ fontSize: "19px" }} />
            </Button>
            </Tooltip>

            <Modal
                title="Reset Flow"
                open={open}
                onCancel={() => setOpen(false)}
                onOk={handleConfirm}
                okText="Delete"
                cancelText="Cancel"
                okButtonProps={{ danger: false }}
            >
                <p>Are you sure you want to reset this flow? This action cannot be undone.</p>
            </Modal>
        </>
    );
};

export default ResetFlow;
