import React, { useState } from "react";
import { Input, Button, notification, Typography } from "antd";
import { GoDatabase } from "react-icons/go";
import { FaRegCopy } from "react-icons/fa";
import CanvasCard from "../../common/CanvasCard";
import Loader from "../../Loader";
import { useAtom } from "jotai";
import { uid, flowId, flowName, predictionDag } from "../../../state/state";
import { predictionApi } from "../../../services/apiHelpers";

const { Text } = Typography;

const GenerateAuthorizationToken = () => {
  const [userInput, setUserInput] = useState("");
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [dag, setDag] = useAtom(predictionDag);

  const [userId] = useAtom(uid);
  const [currentFlowId] = useAtom(flowId);
  const [currentFlowName] = useAtom(flowName);

  const handleGenerateToken = async () => {
    const requestBody = {
      uid: userId,
      flow_name: currentFlowName,
      flow_id: currentFlowId,
      dag: dag,
    };

    try {
      const response = await predictionApi.post("/gen_auth_token", requestBody);
      const token = response.data || "No token received";
      setToken(token);
      setLoading(false);
      notification.success({
        message: "Token Generated",
        description: "Your token has been successfully generated.",
      });
    } catch (error) {
      setLoading(false);
      notification.error({
        message: "Error Generating Token",
        description:
          error.response?.data?.message ||
          error.message ||
          "An error occurred while generating the token.",
      });
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        notification.success({
          message: "Copied!",
          description: "Text has been copied to clipboard.",
        });
      },
      (err) => {
        notification.error({
          message: "Copy Failed",
          description: "Failed to copy text to clipboard.",
        });
      }
    );
  };

  const curlCommand = `curl -X POST https://prediction-no-code-rag-prod.ia-chat-assistant.com/predict_with_token \\
-H "Content-Type: application/json" \\
-H "Authorization: Bearer ${token || "<your_token_here>"}" \\
-d '{
  "flow_name": "<your_flow_name>",
  "query": "<your_user_query>"
}'`;

  return (
    <CanvasCard
      title="Generate Authorization Token"
      headerIcon={<GoDatabase className="text-white text-2xl" />}
      className="config-card"
    >
      <div className="flex flex-col gap-4">
        <div className="input-section">
          <div className="option-label">API Endpoint</div>
          <Input
            value="https://prediction-no-code-rag-prod.ia-chat-assistant.com/gen_auth_token"
            className="input-field"
            readOnly
          />
        </div>

        <div className="input-section">
          <div className="option-label">Prompt Template</div>
          <Input
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            className="input-field"
            placeholder="Enter your prompt template here"
          />
        </div>

        <Button
          type="primary"
          onClick={handleGenerateToken}
          loading={loading}
          className="generate-button"
        >
          Generate Authorization Token
        </Button>

        <div className="input-section">
          <div className="flex items-center justify-between mb-2">
            <div className="option-label">Generated Authorization Token</div>
            <FaRegCopy
              className="cursor-pointer"
              onClick={() =>
                copyToClipboard(token || "your-generated-authorization-token")
              }
            />
          </div>
          <Input
            value={token || "your-generated-authorization-token"}
            className="input-field"
            readOnly
          />
        </div>

        <Text className="font-medium text-center text-white">
          API Documentation
        </Text>
        <div className="flex justify-between items-center">
          <Text className="font-medium text-center text-white">
            Payload Structure
          </Text>
          <FaRegCopy
            className="cursor-pointer"
            onClick={() =>
              copyToClipboard(
                JSON.stringify(
                  {
                    flow_name: currentFlowName || "your_flow_name",
                    query: "your_user_query",
                  },
                  null,
                  2
                )
              )
            }
          />
        </div>
        <pre className="bg-slate-800 p-2 w-full">
          {`{
  "flow_name": "${currentFlowName || "your_flow_name"}",
  "query": "your_user_query"
}`}
        </pre>
        <div className="flex justify-between items-center">
          <Text className="font-medium text-center text-white">cURL</Text>
          <FaRegCopy
            className="cursor-pointer"
            onClick={() => copyToClipboard(curlCommand)}
          />
        </div>
        <pre
          className="bg-slate-800 p-2 w-full overflow-x-auto whitespace-pre"
          style={{ maxWidth: "100%", overflow: "auto" }}
        >
          {curlCommand}
        </pre>
      </div>
    </CanvasCard>
  );
};

export default GenerateAuthorizationToken;