import { dataApi } from "../../../services/apiHelpers";
import { notification } from "antd";
import Loader from "../../Loader";

export const handleCreate =
  (dbName, dbs, setDbs, userId, fid, dbDag, connected) => async () => {
    if (!connected) {
      notification.error({
        message: "Node not part of a flow!",
        description: "Please make the db creation node part of a flow.",
      });
      return;
    }

    if (!dbDag?.length) {
      notification.error({
        message: "No nodes connect!",
        description: "Please connect nodes before creating a flow.",
      });
      return;
    }

    if (!dbName) {
      notification.error({
        message: "Db name cannot be empty",
        description: "Please provide a name for this database.",
      });
      return;
    }

    if (fid === "") {
      notification.error({
        message: "Flow name must not be empty",
        description: "You must save a flow before creating a database.",
      });
      return;
    }

    const data = { dag: dbDag, uid: userId, flow_id: fid };
    notification.info({
      message: "Loading...",
      description: "Database is being created",
      key: "loading",
      duration: 0,
      icon: (
        <span className="m-2">
          <Loader isMini={true} />
        </span>
      ),
    });

    try {
      await dataApi.post("/generate_flow", data, {
        params: { uid: userId, flow_id: fid },
        headers: { "Content-Type": "application/json" },
      });
      notification.destroy();
      notification.success({
        message: "Success",
        description: "Databse creation successful.",
      });

      if (!dbs.includes(dbName)) setDbs([...dbs, dbName]);
    } catch (e) {
      const msg = e?.response?.data?.detail;
      notification.destroy();
      notification.error({
        message: "Error",
        description: msg.includes("No supported document")
          ? "Please upload a document before creating a flow."
          : "Flow creation failed",
      });
      console.log(e);
    }
  };
