import SelectDatabase from "./SelectDatabase";
import KnowledgeRetrieving from "./KnowledgeRetrieving";
import HybridRetrievalConfiguration from "./HybridRetrievalConfiguration";
import BigLLMs from "./BigLLMs";
import TestFlow from "./TestFlow";
import GenerateAuthorizationToken from "./GenerateAuthorizationToken";
import Reranker from "./Reranker";
import HallucinationShield from "./HallucinationShield";

export const nodeTypes = {
  selectDatabase: SelectDatabase,
  knowledgeRetrieving: KnowledgeRetrieving,
  generateAuthorizationToken: GenerateAuthorizationToken,
  hybridRetrievalConfiguration: HybridRetrievalConfiguration,
  bigLLMs: BigLLMs,
  testFlow: TestFlow,
  hallucinationShield: HallucinationShield,
  reranker: Reranker,
};

export const validConnections = {
  "Select Database": ["Knowledge Retrieving", "Hybrid Retrieval Configuration"],
  "Knowledge Retrieving": [
    "Big LLMs",
    "Hallucination Shield",
    "Query Normalization",
    "Reranker",
  ],
  "Hybrid Retrieval Configuration": [
    "Big LLMs",
    "Hallucination Shield",
    "Query Normalization",
    "Reranker",
  ],
  "Query Normalization": ["Hallucination Shield", "Reranker", "Big LLMs"],
  "Hallucination Shield": ["Reranker", "Big LLMs"],
  Reranker: ["Big LLMs"],
  "Big LLMs": [
    "Answer Refinement",
    "Post Generation Hallucination Shield",
    "Test Flow",
  ],
  "Answer Refinement": ["Post Generation Hallucination Shield", "Test Flow"],
  "Post Generation Hallucination Shield": ["Answer Refinement", "Test Flow"],
};
