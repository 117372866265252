import { Modal, notification } from "antd";
import {
  dbCreationDag,
  flowId,
  predictionDag,
  store,
  uid,
} from "../state/state";
import { dataApi } from "../services/apiHelpers";
import { MdRestore } from "react-icons/md";
import { TbWorld } from "react-icons/tb";
import { FaRegFolderOpen } from "react-icons/fa";

export const FlowVariant = {
  Flow: "flow",
  Template: "template",
  GlobalTemplates: "globalTemplates"
}

export const getRoutes = (flowVariant) => {
  switch(flowVariant) {
    case FlowVariant.Flow:
      return { getRoute: "/get_flow", saveRoute: "/save_flow", title: "Restore Flow", icon: <MdRestore /> };
    case FlowVariant.Template:
      return { getRoute: "/get_template", saveRoute: "/save_template", title: "Open My Templates", icon: <FaRegFolderOpen /> };
    case FlowVariant.GlobalTemplates:
      return { getRoute: "/global_templates", saveRoute: "/save_global_template", title: "Open Global Templates", icon: <TbWorld /> };
  }
}

export const getFlowIdFromName = async (flowName, flowVariant) => {
  const userId = store.get(uid);
  const { getRoute } = getRoutes(flowVariant);

  try {
    const res = await dataApi.post( getRoute, {
      user_id: userId,
      flow_id: "",
    });
    const flows = res.data;
    const [fid] = Object.keys(flows).filter(
      (el) => flows[el].flow_name === flowName,
    );

    return fid;
  } catch (e) {
    console.log("Something went wrong");
    return false;
  }
};

const saveFlowOnServer = async (flowName, flowObject, flowType, flowVariant) => {
  const existingFlowId = await getFlowIdFromName(flowName, flowVariant);
  const fid = store.get(flowId);
  const dag =
    flowType === "creation"
      ? store.get(dbCreationDag)
      : store.get(predictionDag);

  if (flowName) store.set(flowId, existingFlowId ? existingFlowId : fid);

  const userId = store.get(uid);
  const { saveRoute } = getRoutes(flowVariant);

  try {
    await dataApi.post(saveRoute, {
      user_id: userId,
      flow_id: existingFlowId ? existingFlowId : fid,
      flow_name: flowName,
      flow_object: { ...flowObject, flowType: flowType, dag: dag },
    });
    notification.success({
      message: "Flow saved successfully!",
      description: "The flow has been saved.",
    });
  } catch (e) {
    notification.error({
      message: "Could not save flow.",
      description: "Something went wrong.",
    });
  }
};

export const saveFlow =
  (
    textFieldVal,
    flows,
    setFlows,
    rfInstance,
    setActiveFlow,
    flowType,
    flowVariant
  ) =>
    () => {
      const flowName = textFieldVal;
      if (!flowName) {
        notification.error({
          message: "Flow name not found!",
          description: "Flow name must not be empty.",
        });
      } else {
        const handleSave = () => {
            const flow = rfInstance.toObject();
            saveFlowOnServer(flowName, flow, flowType, flowVariant);
            setFlows(flows.set(flowName, flow));
            setActiveFlow(flowName);
        };
        Modal.confirm({
          title: "Confirm Save Flow",
          content: `Are you sure you want to save the flow "${flowName}"?`,
          okText: "Yes, Save",
          cancelText: "Cancel",
          onOk: handleSave,
        });
      }
    };

const getFlowObject = async (flowName, flows, flowVariant) => {
  if (flowVariant === FlowVariant.GlobalTemplates)
    return [flows[flowName], null];
  else {
    const id = await getFlowIdFromName(flowName, flowVariant);
    return [flows[id].flow_object, id];
  }
}

export const handleRestore =
  (setNodes, setEdges, setActiveFlow) =>
    async (flowName, flows, flowVariant, setTextFieldValue, setFid) => {
      const [flow, id] = await getFlowObject(flowName, flows, flowVariant);
      const dag = flow.dag;
      if (flow.flowType === "creation") store.set(dbCreationDag, dag);
      else store.set(predictionDag, dag);

      setNodes(flow.nodes || []);
      setEdges(flow.edges || []);
      setActiveFlow(flowName);
      setTextFieldValue(flowName);
      if (flowVariant !== FlowVariant.GlobalTemplates)
        setFid(id);
    };

export const updateProperties =
  (nodeId, dbDag, setdbDag, connected) => (props) => {
    const node = dbDag.find((el) => el.id === nodeId);
    const p = node?.properties;
    const updatedProps = { ...p, ...props };
    const val = { ...node, properties: updatedProps };
    if (connected) setdbDag(dbDag.filter((el) => el !== node).concat(val));
  };
