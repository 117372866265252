import React, { useEffect, useState } from "react";
import { Checkbox, Tabs } from "antd";
import { dbCreationDag } from "../../../state/state";
import { LuBrainCircuit } from "react-icons/lu";
import { textCleaningOptionsList, formattingOptionsList } from "./options";
import styles from "./styles.module.css";
import { useAtom } from "jotai";
import CanvasCard from "../../common/CanvasCard";
import { useNodeId, useReactFlow } from "@xyflow/react";
import { useConnected } from "../../../hooks/useConnected";
import { updateProps } from "./helpers";
import { FiEye } from "react-icons/fi";
import { handleSelectedPreview } from "../helpers";

const { TabPane } = Tabs;

const DataPreprocessingCard = () => {
  const [textCleaningOptions, setTextCleaningOptions] = useState([]);
  const [formattingOptions, setFormattingOptions] = useState([]);
  const [props, setProps] = useState([]);
  const [dbDag, setdbDag] = useAtom(dbCreationDag);
  const nodeId = useNodeId();
  const connected = useConnected();
  const { getEdges } = useReactFlow();

  const tabs = [
    {
      name: "Text Cleaning",
      list: textCleaningOptionsList,
      options: textCleaningOptions,
      setOptions: setTextCleaningOptions,
    },
    {
      name: "Formatting",
      list: formattingOptionsList,
      options: formattingOptions,
      setOptions: setFormattingOptions,
    },
  ];

  useEffect(() => {
    const val = {
      id: nodeId,
      node: "data_preprocessing",
      properties: props,
    };

    if (connected) setdbDag((dag) => [...dag, val]);
  }, [connected]);

  const updateProperties = updateProps(
    nodeId,
    dbDag,
    setdbDag,
    connected,
    setProps,
  );

  return (
    <CanvasCard
      title="Data Preprocessing"
      bordered={false}
      headerIcon={<LuBrainCircuit className="text-white text-2xl" />}
      extraTitleBarContent={
        <FiEye
          className="text-white cursor-pointer"
          style={{ fontSize: "20px" }}
          onClick={handleSelectedPreview(getEdges(), nodeId)}
        />
      }
    >
      <Tabs defaultActiveKey="1">
        {tabs.map((el, ind) => (
          <TabPane tab={el.name} key={ind + 1}>
            <div className={styles.section}>
              <Checkbox.Group
                options={el.list}
                value={el.options}
                onChange={(checkedValues) => {
                  el.setOptions(checkedValues);
                  updateProperties(checkedValues);
                }}
                className="flex flex-row"
              />
            </div>
          </TabPane>
        ))}
      </Tabs>
    </CanvasCard>
  );
};

export default DataPreprocessingCard;
