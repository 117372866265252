const dbTab = {
  title: "Vector Database",
  inputs: [
    {
      label: "Db name",
      key: "name",
      backend_key: "name",
      default: "db1",
    },
  ],
  dropdowns: [
    {
      label: "Vector Database",
      key: "vectorDatabase",
      backend_key: "db",
      helpText: "Select the vector database for storing embeddings.",
      inputTypes: ["FAISS", "Chroma", "DB", "Milvus", "Pinecone"],
    },
    {
      label: "Embedding Model",
      key: "embeddingModel",
      backend_key: "embedding_model",
      helpText: "Select the embedding model for vector creation and retrieval.",
      inputTypes: [
        "OpenAI Embeddings",
        "Sentence Transformers: all-mpnet-base-v2",
        "Sentence Transformers: all-MiniLM-L12-v2",
        "Cohere Embeddings",
        "GTE (Generalized Tuning Embeddings)",
        "Instructor-XL (Instruction-tuned embeddings)",
        "LLaMA Embeddings",
        "E5 Embeddings",
      ],
    },
    {
      label: "Index Type",
      key: "indexType",
      backend_key: "index_type",
      helpText:
        "Select index type (Flat for small datasets, HNSW for large-scale datasets).",
      inputTypes: ["Flat", "HNSW"],
    },
  ],
};

export const TabList = [dbTab];
