import { notification } from "antd";
import { dataApi } from "../../services/apiHelpers";
import {
  dbCreationDag,
  flowId,
  previewAtom,
  showPreviewAtom,
  store,
  uid,
} from "../../state/state";
import Loader from "../Loader";

export const error1 = Symbol("error1");

const requestPreview = async (dag) => {
  const fid = store.get(flowId);
  const userId = store.get(uid);
  notification.info({
    message: "Fetching Preview...",
    description: "The text preview is being fetched",
    key: "fetchPreview",
    duration: 0,
    icon: (
      <span className="m-2">
        <Loader isMini={true} />
      </span>
    ),
  });

  try {
    const res = await dataApi.post("/preview", {
      dag: dag,
      flow_id: fid,
      uid: userId,
    });
    notification.destroy("fetchPreview");
    return res.data;
  } catch (e) {
    notification.destroy("fetchPreview");
    notification.error({
      message: "Could not fetch the preview.",
      description: "Something went wrong.",
    });
    return;
  }
};

export const getPreview = async (dag) => {
  if (dag.length === 0) {
    notification.error({
      message: "Flow incomplete!",
      description: "Cannot show preview for an incomplete flow.",
    });
    return error1;
  } else {
    const res = await requestPreview(dag);
    return res;
  }
};

export const handlePreview = async (dag) => {
  const res = await getPreview(dag);
  if (res && res != error1) {
    store.set(previewAtom, {
      old: res.original_text.slice(0, 200),
      new: res.processed_text.slice(0, 200),
    });
  }
  store.set(showPreviewAtom, true);
};

export const handleSelectedPreview = (edges, nodeId) => (e) => {
  e.stopPropagation();

  const dbDag = store.get(dbCreationDag);
  const [, ...usefulEdges] = edges;

  const getNewDag = () => usefulEdges.reduce(
    (acc, el) =>
      acc.some((f) => f.id === nodeId)
        ? acc
        : acc.concat(dbDag.find((node) => node.id === el.source)),
    [],
  );
  const newDag = edges.length > 1 ? getNewDag() : dbDag;

  handlePreview(newDag);
};
