import { atom, createStore } from "jotai";
import { atomWithStorage } from "jotai/utils";

export const allowRegistrationAtom = atom(false);
export const flowType = atom("db-creation"); // db-creation or generation
export const dbCreationDag = atom([]);
export const flowDag = atom([]);
export const flowName = atom("");
export const flowId = atom(crypto.randomUUID());
export const uid = atom((get) => get(userAtom).username);
export const availableDbs = atom([]);
export const savedCreationFlows = atom(new Map());
export const savedGenerationFlows = atom(new Map());

// Generation flow basic
export const predictionDag = atom([]);
export const selectedDatabaseAtom = atom(null);
export const knowledgeRetrievalConfigAtom = atom({});
export const bigModelConfigAtom = atom({});
export const store = createStore();
export const userAtom = atomWithStorage(
  "user",
  JSON.parse(localStorage.getItem("user")) ?? null,
);
export const previewAtom = atom();
export const showPreviewAtom = atom(false);
