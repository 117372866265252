import React, { useCallback, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import SearchIcon from "@mui/icons-material/Search";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ChevronDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { IoBagRemoveSharp } from "react-icons/io5";
import { FaUser } from "react-icons/fa";
import { InputBase } from "@mui/material";
import {
  applyNodeChanges,
  applyEdgeChanges,
  addEdge,
  MarkerType,
} from "@xyflow/react";

import { CodeSandboxOutlined } from "@ant-design/icons";

import CreationFlow from "./CreationFlow/CreationFlow";
import GenerationFlow from "./GenerationFlow/GenerationFlow";
import stepsCategories from "../constants/categories";
import { flowType, uid } from "../state/state";

const drawerWidth = 280;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    marginRight: 0,
  }),
);

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: "#ededca",
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

const CustomDrawerContent = styled(Box)({
  height: "100%",
  overflow: "hidden",
  position: "relative",
});

export default function PersistentDrawerRight(setEdges, setNodes, isCanvas) {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [flow, setFlow] = useAtom(flowType);
  const [searchTerm, setSearchTerm] = useState("");
  const drawerContentRef = useRef(null);
  const [creationNodes, setCreationNodes] = useState([]);
  const [creationEdges, setCreationEdges] = useState([]);
  // State for the Generation view
  const [generationNodes, setGenerationNodes] = useState([]);
  const [generationEdges, setGenerationEdges] = useState([]);
  const [user] = useAtom(uid);

  const isRoot = location.pathname === "/development-studio";

  const filteredCategories = stepsCategories
    .map((category) => {
      const filteredSteps = category.steps.filter((step) => step.flow === flow);
      const searchedSteps = filteredSteps.filter((step) =>
        step.name.toLowerCase().includes(searchTerm.toLowerCase()),
      );

      return {
        ...category,
        steps: searchedSteps,
      };
    })
    .filter((category) => category.steps.length > 0);

  const customTheme = createTheme({
    typography: {
      fontFamily: "Roboto, Arial, sans-serif",
      h6: {
        fontSize: "0.875rem",
      },
      body2: {
        fontSize: "0.75rem",
      },
    },
  });
  // Handle nodes and edges for Creation
  const handleCreationNodesChange = useCallback((changes) => {
    setCreationNodes((nds) => applyNodeChanges(changes, nds));
  }, []);

  const handleCreationEdgesChange = useCallback((changes) => {
    setCreationEdges((eds) => applyEdgeChanges(changes, eds));
  }, []);

  const handleCreationConnect = useCallback((connection) => {
    const newEdge = {
      id: `${connection.source}-${connection.target}`,
      source: connection.source || "",
      target: connection.target || "",
      animated: true,
      markerEnd: {
        type: MarkerType.Arrow,
      },
    };
    setCreationEdges((eds) => addEdge(newEdge, eds));
  }, []);

  // Handle nodes and edges for Generation
  const handleGenerationNodesChange = useCallback((changes) => {
    setGenerationNodes((nds) => applyNodeChanges(changes, nds));
  }, []);

  const handleGenerationEdgesChange = useCallback((changes) => {
    setGenerationEdges((eds) => applyEdgeChanges(changes, eds));
  }, []);

  const handleGenerationConnect = useCallback((connection) => {
    const newEdge = {
      id: `${connection.source}-${connection.target}`,
      source: connection.source || "",
      target: connection.target || "",
      animated: true,
      markerEnd: {
        type: MarkerType.Arrow,
      },
    };
    setGenerationEdges((eds) => addEdge(newEdge, eds));
  }, []);
  // Define onDragStart function

  const onDragStart = (stepId, event) => {
    event.dataTransfer.setData("application/reactflow", stepId);
    event.dataTransfer.effectAllowed = "move";
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const stepId = event.dataTransfer.getData("application/reactflow");
    console.log("Dropped step ID:", stepId); // Handle the step drop here
  };

  const handleDragOver = (event) => {
    event.preventDefault(); // Necessary for allowing a drop
  };

  const handleFlowChange = (event, newFlow) => {
    if (newFlow !== null) {
      setFlow(newFlow);
    }
  };

  return (
    <ThemeProvider theme={customTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        {/* AppBar */}
        <AppBar position="fixed">
          <Toolbar>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img
                  src="/Images/Inference-Analytics-Logo-No-Background.png"
                  alt="ia"
                  width="200"
                  height="200"
                  onClick={() => navigate("/")}
                  className="cursor-pointer"
                />
              </Box>

              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  flexGrow: 1,
                  fontFamily: "sans-serif",
                  fontSize: "20px",
                  color: "#000000",
                }}
              >
                Inference AI Development Studio
              </Typography>

              <Box sx={{ display: "flex", alignItems: "center" }}>
                <div
                  className="mx-4"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {isRoot ? (
                    <>
                      <IoBagRemoveSharp
                        style={{
                          color: "darkgreen",
                          fontSize: "20px",
                          marginRight: "8px",
                          cursor: "pointer",
                        }}
                        onClick={() => window.open("/documentation", "_blank")}
                      />

                      <span
                        style={{
                          color: "darkgreen",
                          fontSize: "18px",
                          fontWeight: "lighter",
                          margin: "8px 16px 1px 1px",
                          cursor: "pointer",
                        }}
                        onClick={() => window.open("/documentation", "_blank")}
                      >
                        SDK
                      </span>

                      <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{
                          fontFamily: "sans-serif",
                          fontSize: "15px",
                          marginTop: "10px",
                          marginRight: "6px",
                          color: "darkgreen",
                        }}
                      >
                        {`Welcome, ${user}!`}
                      </Typography>

                      <FaUser
                        style={{
                          color: "darkgreen",
                          fontSize: "20px",
                        }}
                        onClick={() => navigate("/")}
                      />
                    </>
                  ) : (
                    <>
                      <CodeSandboxOutlined
                        style={{
                          color: "darkgreen",
                          fontSize: "20px",
                          marginRight: "8px",
                        }}
                        onClick={() => navigate("/development-studio")}
                      />

                      <span
                        style={{
                          color: "darkgreen",
                          fontSize: "18px",
                          fontWeight: "bold",
                          margin: "0 16px",
                          cursor: "pointer",
                        }}
                        onClick={() => navigate("/development-studio")}
                      >
                        Studio
                      </span>
                    </>
                  )}
                </div>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>

        {/* Main Content */}
        <Main onDrop={handleDrop} onDragOver={handleDragOver}>
          <DrawerHeader />
          {flow === "db-creation" ? (
            <CreationFlow
              nodes={creationNodes}
              setNodes={setCreationNodes}
              edges={creationEdges}
              setEdges={setCreationEdges}
              onNodesChange={handleCreationNodesChange}
              onEdgesChange={handleCreationEdgesChange}
              onConnect={handleCreationConnect}
            />
          ) : (
            <GenerationFlow
              nodes={generationNodes}
              setNodes={setGenerationNodes}
              edges={generationEdges}
              setEdges={setCreationEdges}
              onNodesChange={handleGenerationNodesChange}
              onEdgesChange={handleGenerationEdgesChange}
              onConnect={handleGenerationConnect}
            />
          )}
        </Main>

        {/* Persistent Drawer */}
        <Drawer
          sx={{
            width: drawerWidth,
            height: 100,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              height: "89vh",
              marginTop: "10vh",
              marginRight: "1vh",
              borderRadius: "5px",
              backgroundColor: "#000000",
            },
          }}
          variant="permanent"
          anchor="right"
          open
        >
          <DrawerHeader>
            <Box sx={{ display: "flex", marginRight: "1px" }}>
              <Box
                sx={{
                  borderRadius: "5px",
                  width: "278px",
                  marginLeft: "-8px",
                }}
              >
                <ToggleButtonGroup
                  value={flow}
                  exclusive
                  onChange={handleFlowChange}
                  aria-label="flow mode"
                  sx={{
                    display: "flex",
                    "& .MuiToggleButton-root": {
                      fontSize: "0.75rem",
                      padding: "4px 12px",
                      border: "2px solid #43413A",
                      borderRadius: "5px 5px 0 0",
                      flex: 1,
                      color: "white",
                      transition: "all 0.2s ease",
                      marginTop: "-31px",
                    },
                    "& .Mui-selected": {
                      backgroundColor: "#43413A !important",
                      color: "white !important",
                    },
                    "& .MuiToggleButtonGroup-grouped:not(:last-of-type)": {
                      borderRight: "none",
                    },
                  }}
                >
                  <ToggleButton
                    value="db-creation"
                    aria-label="creation flow"
                    sx={{
                      color: "white",
                    }}
                  >
                    Creation Flow
                  </ToggleButton>
                  <ToggleButton
                    value="generation"
                    aria-label="generation flow"
                    sx={{
                      color: "white",
                    }}
                  >
                    Generation Flow
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </Box>
          </DrawerHeader>

          {/* Search Bar */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: theme.spacing(1, 2),
            }}
          >
            <SearchIcon sx={{ color: "#fff" }} />
            <InputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              sx={{ marginLeft: theme.spacing(1), flex: 1, color: "#fff" }}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Box>

          {/* Scroll Controls */}
          <IconButton
            onClick={() =>
              drawerContentRef.current.scrollBy({
                top: -100,
                behavior: "smooth",
              })
            }
            sx={{ color: "#fff", backgroundColor: "#000000" }}
          >
            <ChevronUpIcon />
          </IconButton>

          <CustomDrawerContent ref={drawerContentRef}>
            <List>
              {filteredCategories.map((category) => (
                <Accordion key={category.category} defaultExpanded>
                  <AccordionSummary
                    sx={{
                      backgroundColor: "#000000",
                      padding: "4px 10px",
                    }}
                    expandIcon={<ChevronRightIcon sx={{ color: "#fff" }} />}
                    aria-controls={`panel-${category.category}-content`}
                    id={`panel-${category.category}-header`}
                  >
                    <Typography
                      sx={{ color: "#fff", fontSize: "0.875rem" }}
                      variant="h6"
                    >
                      <ListItemIcon sx={{ color: "#fff", minWidth: "24px" }}>
                        {category.categoryIcon}
                      </ListItemIcon>
                      {category.category}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      backgroundColor: "#000000",
                      padding: "4px 10px",
                    }}
                  >
                    <List disablePadding>
                      {category.steps.map((step) => (
                        <ListItem
                          key={step.id}
                          disablePadding
                          draggable
                          onDragStart={(e) => onDragStart(step.id, e)}
                          sx={{ margin: "2px 0" }}
                        >
                          <ListItemButton
                            sx={{
                              padding: "4px 10px",
                            }}
                          >
                            <ListItemIcon
                              sx={{ color: "#fff", minWidth: "24px" }}
                            >
                              {/* {step.icon} */}
                            </ListItemIcon>
                            <ListItemText
                              primary={step.name}
                              sx={{ color: "#fff", fontSize: "0.75rem" }}
                            />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </AccordionDetails>
                </Accordion>
              ))}
            </List>
          </CustomDrawerContent>

          {/* Scroll Down Control */}
          <IconButton
            onClick={() =>
              drawerContentRef.current.scrollBy({
                top: 100,
                behavior: "smooth",
              })
            }
            sx={{ color: "#fff", backgroundColor: "#000000" }}
          >
            <ChevronDownIcon />
          </IconButton>
        </Drawer>
      </Box>
    </ThemeProvider>
  );
}
