import CanvasCard from "../../common/CanvasCard";
import { BsGear } from "react-icons/bs";
import { useEffect } from "react";
import { useAtom } from "jotai";
import { knowledgeRetrievalConfigAtom } from "../../../state/state";

const HallucinationShield = () => {
  const [config, setConfig] = useAtom(knowledgeRetrievalConfigAtom);

  useEffect(() => {
    const p = config.properties;
    const val = { ensemble: { hallucination_shield: "true" } };
    const updatedProps = { ...p, ...val };
    setConfig({ ...config, properties: { ...updatedProps } });
  }, []);

  return (
    <CanvasCard
      title="Hallucination Shield"
      headerIcon={<BsGear className="text-white text-2xl" />}
    ></CanvasCard>
  );
};

export default HallucinationShield;
