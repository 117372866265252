import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { userAtom } from "../../state/state";
import styles from "./Login.module.css";
import { IoEyeSharp, IoEyeOffSharp } from "react-icons/io5";
import { dataApi } from "../../services/apiHelpers";

const Signup = () => {
    const [formData, setFormData] = useState({
        username: "",
        email: "",
        password: "",
        confirmPassword: "",
        zipcode: "",
    });
    const [errors, setErrors] = useState({});
    const [successMessage, setSuccessMessage] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [user, setUser] = useAtom(userAtom);
    const navigate = useNavigate();

    const validateForm = () => {
        const newErrors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const passwordRegex =
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;

        if (!formData.username) newErrors.username = "Username is required.";
        if (!formData.email) newErrors.email = "Email is required.";
        else if (!emailRegex.test(formData.email)) newErrors.email = "Invalid email format.";
        if (!formData.password) newErrors.password = "Password is required.";
        else if (!passwordRegex.test(formData.password))
            newErrors.password =
                "Password must be at least 8 characters, include uppercase, lowercase, a number, and a special character.";

        if (!formData.zipcode) newErrors.zipcode = "Zip code is required.";
        else if (!/^\d{5}$/.test(formData.zipcode)) newErrors.zipcode = "Zip code must be a 5-digit number.";

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleRegister = async () => {
        console.log("Register button clicked");

        const isValid = validateForm();
        console.log("Form validation result:", isValid);
        if (!isValid) return;

        try {
            console.log("Attempting API call with data:", formData);
            const response = await dataApi.post("/register", formData);
            console.log("API Response:", response);
            if (response.data) {
                setUser(response.data);
                console.log("User saved to state:", response.data);
            }
            setSuccessMessage("Registration successful! Redirecting...");
            navigate("/");
        } catch (error) {
            console.error("Error during registration:", error);
            if (error.response?.status === 400 && error.response?.data?.detail) {
                setErrors({ server: error.response.data.detail });
            } else {
                setErrors({ server: "An error occurred during registration. Please try again." });
            }
        }
    };



    return (
        <div className={styles.container}>
            <div className={styles.card}>
                <h1 className={styles.title}>Inference AI Development Studio</h1>
                <p className={styles.subtitle}>Create your account to get started.</p>

                {errors.server && <p className={styles.errorText}>{errors.server}</p>}
                {successMessage && <p className={styles.successText}>{successMessage}</p>}

                <div className={styles.inputGroup}>
                    <label>Username</label>
                    <input
                        type="text"
                        placeholder="Enter your username"
                        value={formData.username}
                        onChange={(e) => setFormData({ ...formData, username: e.target.value })}
                        className={styles.input}
                    />
                    {errors.username && <p className={styles.errorText}>{errors.username}</p>}
                </div>

                <div className={styles.inputGroup}>
                    <label>Email</label>
                    <input
                        type="email"
                        placeholder="Enter your email"
                        value={formData.email}
                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                        className={styles.input}
                    />
                    {errors.email && <p className={styles.errorText}>{errors.email}</p>}
                </div>

                <div className={styles.inputGroup}>
                    <label>Password</label>
                    <div className={styles.passwordWrapper}>
                        <input
                            type={showPassword ? "text" : "password"}
                            placeholder="Enter your password"
                            value={formData.password}
                            onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                            className={styles.input}
                        />
                        <span
                            className={styles.eyeIcon}
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            {showPassword ? <IoEyeSharp /> : <IoEyeOffSharp />}
                        </span>
                    </div>
                    {errors.password && <p className={styles.errorText}>{errors.password}</p>}
                </div>

                <div className={styles.inputGroup}>
                    <label>Zip Code</label>
                    <input
                        type="text"
                        placeholder="Enter your zip code"
                        value={formData.zipcode}
                        onChange={(e) => setFormData({ ...formData, zipcode: e.target.value })}
                        className={styles.input}
                    />
                    {errors.zipcode && <p className={styles.errorText}>{errors.zipcode}</p>}
                </div>

                <div className={styles.actions}>
                    <button className={styles.registerButton} onClick={handleRegister}>
                        Register
                    </button>
                </div>

                <div className={styles.loginPrompt}>
                    <p>
                        Already have an account?{" "}
                        <span
                            className={styles.loginLink}
                            onClick={() => navigate("/")}
                        >
                            Login
                        </span>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Signup;
