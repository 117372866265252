import UploadCard from "./UploadCard";
import DataPreprocessingCard from "./DataPreprocessing";
import ChunkingDataNode from "./DataChunking";
import VectorDatabaseNode from "./CreateDatabase";
import PHI from "./PHI";

export const nodeTypes = {
  uploadCard: UploadCard,
  dataPreprocessing: DataPreprocessingCard,
  chunkingData: ChunkingDataNode,
  vectorDatabaseCreation: VectorDatabaseNode,
  phi: PHI,
};

export const validConnections = {
  "Upload Data": ["Data Preprocessing", "PHI Redaction", "Chunking Data"],
  "Data Preprocessing": ["PHI Redaction", "Chunking Data"],
  "PHI Redaction": ["Data Preprocessing", "Chunking Data"],
  "Chunking Data": [
    "Hypothetical Query Generation",
    "Vector Database Creation",
    "Knowledge Graph Construction",
  ],
  "Vector Database Creation": ["Knowledge Graph Construction"],
  "Knowledge Graph Construction": ["Vector Database Creation"],
};
