import { Dropdown, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import styles from "./styles.module.css";

const DropdownInput = ({
  title,
  options,
  helpText,
  selectedOption,
  handleMenuClick,
}) => {
  const [val, setVal] = useState(selectedOption);

  const menuItems = options.map((el) => {
    return (
      <Menu.Item
        key={el.name ? el.name : el}
        className="text-white bg-[#555]"
        onClick={el.onClick ? el.onClick : () => {}}
      >
        {el.name ? el.name : el}
      </Menu.Item>
    );
  });

  const dropdownMenu = (
    <Menu
      onClick={(e) => {
        setVal(e.key);
        if (handleMenuClick) handleMenuClick(e.key);
      }}
    >
      {menuItems}
    </Menu>
  );

  useEffect(() => {
    const [firstOption] = options;
    if (!options.includes(val)) setVal(firstOption);
  }, [options]);

  return (
    <div>
      <div className="text-white py-1 text-bold">{title}</div>
      <Dropdown overlay={dropdownMenu} trigger={["click"]}>
        <div className="flex justify-between items-center w-full h-12 p-2.5 bg-[#444] text-white rounded cursor-pointer">
          <span>{val}</span>
          <FiChevronDown className="text-white" />
        </div>
      </Dropdown>
      {helpText && <div className={styles.help_text}>{helpText}</div>}
    </div>
  );
};

export default DropdownInput;
