import React from "react";
import ChatbotComponent from "../components/ChatbotComponent";

const ChatbotPage = () => {
  return (
    <section className="min-h-[100vh] w-full">
      <ChatbotComponent />
    </section>
  );
};

export default ChatbotPage;
