import React, { useEffect, useState } from "react";
import { availableDbs, dbCreationDag, flowId, uid } from "../../../state/state";
import { Tabs } from "antd";
import { LuBrainCircuit } from "react-icons/lu";
import { useAtom, useAtomValue } from "jotai";
import { Button } from "antd";
import { handleCreate } from "./helpers";
import { TabList } from "./options";
import TextInput from "../../common/TextInput";
import DropdownInput from "../../common/DropdownInput";
import CanvasCard from "../../common/CanvasCard";
import { useNodeId } from "@xyflow/react";
import { useConnected } from "../../../hooks/useConnected";

const { TabPane } = Tabs;

const CreateDatabase = () => {
  const [vectorDatabase, setVectorDatabase] = useState("Chroma DB");
  const [embeddingModel, setEmbeddingModel] = useState("all-mpnet-base-v2");
  const [indexType, setIndexType] = useState("Flat");
  const [dbName, setDbName] = useState("");
  const [props, setProps] = useState({
    db: vectorDatabase,
    embedding_model: embeddingModel,
    index_type: indexType,
  });
  const [dbDag, setdbDag] = useAtom(dbCreationDag);
  const [dbs, setDbs] = useAtom(availableDbs);
  const userId = useAtomValue(uid);
  const fid = useAtomValue(flowId);
  const nodeId = useNodeId();
  const connected = useConnected();

  const updateProperties = (newProps) => {
    const node = dbDag.find((el) => el.name === dbName);
    const updatedProps = { ...node?.properties, ...props, ...newProps };
    const val = {
      name: dbName,
      properties: updatedProps,
    };
    setProps(updatedProps);
    if (connected)
      setdbDag(dbDag.filter((el) => el.name !== dbName).concat(val));
  };

  const handleChange = (f, k) => (value) => {
    f(value);
    updateProperties({ [k]: value });
  };

  const handleNameChange = (e) => {
    const node = dbDag.find((el) => el.name === dbName);
    const name = e.target.value;
    setDbName(name);
    const updatedNode = { ...node, name: name };
    if (connected)
      setdbDag(dbDag.filter((el) => el !== node).concat(updatedNode));
  };

  const getDropdownHandler = (key) => {
    switch (key) {
      case "vectorDatabase":
        return handleChange(setVectorDatabase, "db");
      case "embeddingModel":
        return handleChange(setEmbeddingModel, "embeddingModel");
      case "indexType":
        return handleChange(setIndexType, "index_type");
    }
  };

  const getInputHandler = (key) => {
    switch (key) {
      case "name":
        return handleNameChange;
    }
  };

  const getInputVal = (key) => {
    switch (key) {
      case "vectorDatabase":
        return vectorDatabase;
      case "embeddingModel":
        return embeddingModel;
      case "indexType":
        return indexType;
    }
  };

  useEffect(() => {
    const nodeCount = dbDag.filter((el) => el.node === "vector_db").length;
    const name = `vector_db_${nodeCount + 1}`;
    const val = {
      id: nodeId,
      name: name,
      description: `vectordb_${nodeCount + 1}`,
      node: "vector_db",
      properties: props,
    };
    setDbName(name);
    if (connected) setdbDag((dag) => [...dag, val]);
  }, [connected]);

  return (
    <CanvasCard
      title="Create Database"
      bordered={false}
      headerIcon={<LuBrainCircuit className="text-white text-2xl" />}
    >
      <Tabs defaultActiveKey="1" className="config-tabs">
        {TabList.map((tab, ind) => (
          <TabPane tab={tab.title} key={ind + 1}>
            {tab.dropdowns.map((item) => (
              <DropdownInput
                title={item.label}
                options={item.inputTypes}
                selectedOption={getInputVal(item.key)}
                handleMenuClick={getDropdownHandler(item.key)}
                helpText={item.helpText}
                key={item.label}
              />
            ))}
            {tab.inputs.map((item) => (
              <TextInput
                label={item.label}
                description={item.helpText}
                onChange={getInputHandler(item.key)}
                key={item.label}
                defaultValue={item.default}
              />
            ))}
          </TabPane>
        ))}
      </Tabs>
      <div className="flex justify-center align-center">
        <Button
          type="primary"
          onClick={handleCreate(
            dbName,
            dbs,
            setDbs,
            userId,
            fid,
            dbDag,
            connected
          )}
        >
          Create Database
        </Button>
      </div>
    </CanvasCard>
  );
};

export default CreateDatabase;
