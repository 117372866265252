import { notification } from "antd";
import { dataApi } from "../services/apiHelpers";

export const uploadFile = async (setFileNames, userId, fid, file) => {
  const formData = new FormData();
  formData.append("files", file);
  try {
    await dataApi.post("/upload_file", formData, {
      params: { uid: userId, flow_id: fid },
      headers: { "Content-Type": "multipart/form-data" },
    });
    notification.success({
      message: "Upload Successful",
      description: "Your file has been uploaded successfully.",
    });
    updateFileNames(setFileNames, userId, fid);
  } catch (e) {
    console.log(e);
  }
};

export const handleDeleteFile = async (fileName, userId, fid, setFileNames) => {
  try {
    await dataApi.post("/delete_object", {
      user_id: userId,
      flow_id: fid,
      file_name: fileName,
    });
    setFileNames((fileNames) => fileNames.filter((name) => name !== fileName));
    notification.success({
      message: "File Deleted",
      description: `The file "${fileName}" has been deleted.`,
    });
  } catch (e) {
    console.error("Error deleting file:", e);
    notification.error({
      message: "Deletion Error",
      description: "Unable to delete the file. Please try again.",
    });
  }
};

export const updateFileNames = async (setFileNames, userId, fid) => {
  const files = await getFileNames(userId, fid);
  setFileNames(files);
};

const getFileNames = async (userId, fid) => {
  try {
   const res = await dataApi.post("/list_files", {
    user_id: userId,
    flow_id: fid,
  });
    return res.data;
  } catch (e) {
    console.log(e);
  }
};
