import { FiTrash } from "react-icons/fi";
import { dbCreationDag, predictionDag } from "../../../state/state";
import { useNodeId, useReactFlow } from "@xyflow/react";
import { useAtom } from "jotai";

export const CardTitle = ({
  title,
  isCollapsed,
  setIsCollapsed,
  headerIcon,
  extraContent,
}) => {
  const [dbDag, setdbDag] = useAtom(dbCreationDag);
  const [pDag, setpdag] = useAtom(predictionDag);
  const { setNodes, getNodes } = useReactFlow();
  const id = useNodeId();

  const deleteCard = () => {
    const updatedNodes = getNodes().filter((el) => el.id !== id);
    setNodes(updatedNodes);
    setdbDag(dbDag.filter((el) => el.id !== id));
    setpdag(pDag.filter((el) => el.id !== id));
  };

  return (
    <div
      className={`flex items-center ${isCollapsed ? "gap-2" : "gap-4"}`}
      style={{
        justifyContent: isCollapsed ? "start" : "space-between",
        borderRadius: isCollapsed ? "1px" : "5px 1px 0 0",
        padding: "5px 10px",
        transition: "all 0.3s ease",
      }}
      onClick={() => setIsCollapsed(!isCollapsed)}
    >
      {headerIcon && (
        <div
          style={{
            fontSize: isCollapsed ? "16px" : "18px",
            transition: "font-size 0.3s ease",
          }}
        >
          {headerIcon}
        </div>
      )}
      <span
        className={`text-white ${isCollapsed ? "text-sm" : "text-base"}`}
        style={{ marginLeft: headerIcon ? "5px" : "10px" }}
      >
        {title}
      </span>
      {extraContent}
      <div
        onClick={(e) => {
          e.stopPropagation();
          deleteCard();
        }}
        className="delete-button text-red-500"
      >
        <FiTrash
          className={`text-red-500 cursor-pointer ${
            isCollapsed ? "text-lg" : "text-lg"
          }`}
        />
      </div>
    </div>
  );
};
