import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import CanvasCard from "../../common/CanvasCard";
import DropdownInput from "../../common/DropdownInput";
import DeleteIcon from "@mui/icons-material/Delete";
import { FiUpload } from "react-icons/fi";
import { useAtom } from "jotai";
import { uid, flowId, flowName } from "../../../state/state";
import {
  handleDeleteFile,
  updateFileNames,
  uploadFile,
} from "../../../helpers/files";
import styles from "./styles.module.css";

const InputTypes = ["Documents", "Datasets", "Structured Data"];
const getFormats = (selectedOption) => {
  switch (selectedOption) {
    case "Documents":
      return ["PDF", "Word Documents (DOCX)", "Text Files (TXT)"];
    case "Datasets":
      return ["CSV", "Excel Files (XLSX)", "JSON"];
    case "Structured Data":
      return ["Database Export (SQL, NoSQL)", "XML", "RDF/Turtle"];
    default:
      return [];
  }
};

const UploadCard = () => {
  const [input, setInput] = useState("Documents");
  const [format, setFormat] = useState("PDF");
  const [fileNames, setFileNames] = useState([]);
  const [userId] = useAtom(uid);
  const [fid] = useAtom(flowId);
  const [flow] = useAtom(flowName);

  const dropZone = (
    <Dropzone
      onDrop={(acceptedFiles) => {
        const [file] = acceptedFiles;
        uploadFile(setFileNames, userId, fid, file);
      }}
    >
      {({ getRootProps, getInputProps }) => (
        <section className={styles.dropzone} {...getRootProps()}>
          <input {...getInputProps()} />
          <p>Drag 'n' drop file here, or click to select files</p>
        </section>
      )}
    </Dropzone>
  );

  useEffect(() => {
    if (flow !== "") updateFileNames(setFileNames, userId, fid);
  }, [fid, flow]);

  return (
    <CanvasCard
      className={styles.background_black}
      title="Upload Data"
      headerIcon={<FiUpload className="text-white text-2xl" />}
    >
      <DropdownInput
        title="Select Option"
        options={InputTypes}
        selectedOption={input}
        handleMenuClick={(v) => setInput(v)}
      />
      <DropdownInput
        title="Select Format"
        options={getFormats(input)}
        selectedOption={format}
        handleMenuClick={(v) => setFormat(v)}
      />
      {dropZone}
      {fileNames?.length > 0 && (
        <div className="max-h-20 overflow-auto gap-3 border-dashed border-2 border-white text-white">
          {fileNames.map((el) => (
            <div key={el} className="flex justify-between items-center p-2">
              <span>{el}</span>
              <DeleteIcon
                className="cursor-pointer text-red-500 hover:text-red-700"
                onClick={() => handleDeleteFile(el, userId, fid, setFileNames)}
              />
            </div>
          ))}
        </div>
      )}
    </CanvasCard>
  );
};

export default UploadCard;
