import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoEyeSharp, IoEyeOffSharp } from "react-icons/io5";
import { dataApi } from "../../services/apiHelpers";
import { useAtom, useAtomValue } from "jotai";
import { allowRegistrationAtom, userAtom } from "../../state/state";
import styles from "./Login.module.css";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [user, setUser] = useAtom(userAtom);
  const isRegistationAllowed = useAtomValue(allowRegistrationAtom);
  const navigate = useNavigate();

  const handleLogin = async () => {
    if (!username || !password) {
      setErrorMessage("Username and password are required");
      return;
    }

    try {
      const response = await dataApi.post("/login", { username, password });

      if (response.data.status_code === 200) {
        setUser(response.data.user);
        console.log("User saved to state:", response.data.user);

        navigate("/development-studio");
      } else {
        setErrorMessage(response.data.message || "Invalid login credentials");
      }
    } catch (error) {
      setErrorMessage(
        error.response?.data?.detail || "An error occurred during login",
      );
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <h1 className={styles.title}>Inference AI Development Studio</h1>
        <p className={styles.subtitle}>Login to continue</p>

        {errorMessage && <p className={styles.errorText}>{errorMessage}</p>}

        <div className={styles.inputGroup}>
          <label>Username</label>
          <input
            type="text"
            placeholder="Enter your username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className={styles.input}
          />
        </div>

        <div className={styles.inputGroup}>
          <label>Password</label>
          <div className={styles.passwordWrapper}>
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={styles.input}
            />
            <span
              className={styles.eyeIcon}
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <IoEyeSharp /> : <IoEyeOffSharp />}
            </span>
          </div>
        </div>

        <div className={styles.actions}>
          <button className={styles.loginButton} onClick={handleLogin}>
            Login
          </button>
        </div>

        {isRegistationAllowed && <div className={styles.signupPrompt}>
            <p>
              Don't have an account?{" "}
              <span
                className={styles.signupLink}
                onClick={() => navigate("/register")}
              >
                Sign up
              </span>
            </p>
          </div>
        }
      </div>
    </div>
  );
};

export default Login;
