import React, { useState } from "react";
import { Button, notification } from "antd";
import { GoDatabase } from "react-icons/go";
import { useAtom } from "jotai";
import {
  flowId,
  flowName,
  predictionDag,
  bigModelConfigAtom,
  selectedDatabaseAtom,
  knowledgeRetrievalConfigAtom,
} from "../../../state/state";
import CanvasCard from "../../common/CanvasCard";
import { defaultGenerationPrompt } from "../../../constants";

const TestFlow = () => {
  const [userInput, setUserInput] = useState("");
  const [selectedDatabase] = useAtom(selectedDatabaseAtom);
  const [currentFlowId] = useAtom(flowId);
  const [currentFlowName] = useAtom(flowName);
  const [knowledgeRetrievalConfig] = useAtom(knowledgeRetrievalConfigAtom);
  const [bigModelConfig] = useAtom(bigModelConfigAtom);
  const [dag, setDag] = useAtom(predictionDag);
  const [loading, setLoading] = useState(false);

  const handleStartChat = () => {
    if (currentFlowId === "") {
      notification.error({
        message: "Flow name must not be empty",
        description: "You must save a flow before starting a chat.",
      });
      return;
    }

    if (!selectedDatabase) {
      notification.error({
        message: "No Database Selected",
        description: "Please select a database before starting a chat.",
      });
      return;
    }

    setLoading(true);

    const dag = [];

    if (
      knowledgeRetrievalConfig &&
      Object.keys(knowledgeRetrievalConfig).length > 0
    ) {
      const {
        searchType,
        similarityScoreThreshold,
        topKStandard,
        topKMultiQuery,
        topKSmartCompression,
        topKLongDoc,
        distanceMetric,
        activeTabKey,
      } = knowledgeRetrievalConfig;

      let retrievalType = "standard";
      let kValue = topKStandard;

      if (activeTabKey === "1") {
        retrievalType = "similarity";
        kValue = topKStandard;
      } else if (activeTabKey === "2") {
        retrievalType = "similarity_score_threshold";
        kValue = topKMultiQuery;
      } else if (activeTabKey === "3") {
        retrievalType = "mmr";
        kValue = topKSmartCompression;
      }

      const retrievalNode = {
        id_: "retrieval_01",
        node: "retrieval",
        properties: {
          ensemble: [
            {
              db_type: "vector_db", // kg (if knowledge graph is selected in creation); vector_db (if vector db is selected in creation)
              db_name: selectedDatabase.db_id,
              search_type: searchType?.toLowerCase(),
              distance_metric: distanceMetric?.toLowerCase(),
              k: kValue,
              similarity_score_threshold: similarityScoreThreshold,
              search_type: retrievalType,
              reranking: true,
            },
          ],
        },
      };

      dag.push(retrievalNode);
    }

    const llmProperties = {
      model: {
        model_name: "gpt-40-mini",
        parameters: {
          temperature: 0.2,
          top_p: 0.3,
          max_tokens: 2048,
        },
      },
      prompt_template: userInput,
    };

    if (bigModelConfig && Object.keys(bigModelConfig).length > 0) {
      const {
        modelName,
        temperature,
        maxTokens,
        topP,
        frequencyPenalty,
        presencePenalty,
        stopSequences,
        promptTemplate,
      } = bigModelConfig;

      llmProperties.model.model_name = modelName;
      llmProperties.model.parameters.temperature = temperature;
      llmProperties.model.parameters.top_p = topP;
      llmProperties.model.parameters.max_tokens = maxTokens;
      llmProperties.model.parameters.frequency_penalty = frequencyPenalty;
      llmProperties.model.parameters.presence_penalty = presencePenalty;

      if (stopSequences) {
        llmProperties.model.parameters.stop = stopSequences
          .split(",")
          .map((s) => s.trim());
      }

      llmProperties.prompt_template = promptTemplate || defaultGenerationPrompt;
    }

    const llmNode = {
      id_: "llm_01",
      node: "llm",
      input: "",
      properties: llmProperties,
    };

    dag.push(llmNode);

    const submitNode = {
      node: "submit",
      input: "",
      properties: {},
    };

    dag.push(submitNode);

    localStorage.setItem("chatbotDag", JSON.stringify(dag));
    localStorage.setItem("currentFlowName", currentFlowName);

    setLoading(false);
    const chatbotUrl = `/chatbot?flowId=${currentFlowId}`;
    window.open(chatbotUrl, "_blank");
  };

  return (
    <CanvasCard
      title="Test Flow"
      headerIcon={<GoDatabase className="text-white text-2xl" />}
      className="upload-card"
    >
      <div className="flex flex-col gap-4">
        <div className="flex justify-center items-center">
          <Button type="primary" onClick={handleStartChat} className="mt-4">
            Start Chat
          </Button>
        </div>
      </div>
    </CanvasCard>
  );
};

export default TestFlow;
