import React, { useCallback, useEffect, useRef, useState } from "react";
import { ReactFlow } from "@xyflow/react";
import stepsData from "../../constants/categories";
import RightToolbar from "../common/RightToolbar"
import {
  handleNodeClick,
  onDragOver,
  onDropNode,
} from "../../helpers/canvasHelpers";

import { nodeTypes, validConnections } from "./constants";
import "../paper.css";
import styles from "./styles.module.css";
import { nameIdMap } from "../../constants/categories";
import ReactDiffViewer from "react-diff-viewer-continued";
import Toolbar from "../common/Toolbar";
import { useAtom } from "jotai";
import { previewAtom, showPreviewAtom } from "../../state/state";

const CreationFlow = ({
  nodes,
  edges,
  onNodesChange,
  onEdgesChange,
  onConnect,
  setNodes,
  setEdges,
}) => {
  const [_selectedStep, setSelectedStep] = useState(null);
  const containerRef = useRef(null);
  const [_isLoginPopupOpen, setLoginPopupOpen] = useState(false);
  const [rfInstance, setRfInstance] = useState(null);
  const [preview, setPreview] = useAtom(previewAtom);
  const [showPreview, setShowPreview] = useAtom(showPreviewAtom);

  useEffect(() => {
    setLoginPopupOpen(true);
  }, []);

  const onDrop = useCallback(
    onDropNode(containerRef, stepsData, nodes, setNodes),
    [nodes],
  );

  return (
    <div
      style={{
        height: "89vh",
        display: "flex",
        flexDirection: "column",
        marginTop: "-17px",
        marginRight: "-6px",
        marginLeft: "-15px",
        marginBottom: "-20px",
      }}
    >
      <RightToolbar />
      <Toolbar
        setNodes={setNodes}
        setEdges={setEdges}
        rfInstance={rfInstance}
        setPreview={setPreview}
        setShowPreview={setShowPreview}
        showPreview={showPreview}
        flowType="creation"
      />

      <div
        ref={containerRef}
        onDrop={onDrop}
        onDragOver={onDragOver}
        style={{
          flex: 1, position: "relative",
        }}
      >
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onNodeClick={handleNodeClick(stepsData, setSelectedStep)}
          onInit={setRfInstance}
          proOptions={{ hideAttribution: true }}
          nodeTypes={nodeTypes}
          fitView
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "#000000",
            borderRadius: "5px",

          }}
          isValidConnection={(connection) => {
            const source = nameIdMap.get(connection.source.slice(0, -2));
            const target = nameIdMap.get(connection.target.slice(0, -2));

            return validConnections[source].includes(target);
          }}
        />
      </div>
      {preview && showPreview && (
        <div className={styles.preview}>
          <ReactDiffViewer
            oldValue={preview?.old}
            newValue={preview?.new}
            splitView={true}
            useDarkTheme
          />
        </div>
      )}
      <RightToolbar rfInstance={rfInstance} />

    </div>
  );
};

export default CreationFlow;
