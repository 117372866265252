import ProtectedRoute from "../components/common/ProtectedRoute";
import PersistentDrawerRight from "../components/rightSide";

function Canvas() {

  return (
    <ProtectedRoute>
      <PersistentDrawerRight></PersistentDrawerRight>
    </ProtectedRoute>
  );
}

export default Canvas;
