import React, { useState } from "react";
import { Modal, List, Typography, IconButton, Box } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const AddCard = ({ newCardOptions, onAddNode }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => setIsModalVisible(true);
  const handleClose = () => setIsModalVisible(false);

  const handleAddNode = (node) => {
    onAddNode(node);
    setIsModalVisible(false);
  };

  return (
    <Box>
      {/* Button to open modal */}
      <IconButton onClick={showModal} sx={{ color: "white" }}>
        <AddCircleOutlineIcon />
      </IconButton>

      {/* Modal with customized styling */}
      <Modal open={isModalVisible} onClose={handleClose} className="relative">
        <Box
          sx={{
            width: "50%",
            maxWidth: 300,
            // bgcolor: "#43413A",
            bgcolor: "#1E1E2D",
            color: "white",
            padding: 3,
            borderRadius: 2,
            margin: "auto",
            position: "absolute",
            top: "23vh",
            left: "37vw",
          }}
        >
          <Typography
            variant="h6"
            sx={{ mb: 2, color: "white", textAlign: "center" }}
          >
            Suggested New Node
          </Typography>

          {/* List of options */}
          <List>
            {newCardOptions.map((option) => (
              <Box
                key={option.name}
                onClick={() => handleAddNode(option)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: 2,
                  borderRadius: 1,
                  cursor: "pointer",
                  "&:hover": {
                    bgcolor: "#333344",
                  },
                }}
              >
                {/* Display icon with white color */}
                <Box sx={{ color: "white", mr: 2 }}>{option.icon}</Box>
                {/* Option name and description */}
                <Box>
                  <Typography sx={{ color: "white", fontWeight: 500 }}>
                    {option.name}
                  </Typography>
                  <Typography sx={{ color: "white", fontSize: "0.875rem" }}>
                    {option.description}
                  </Typography>
                </Box>
              </Box>
            ))}
          </List>
        </Box>
      </Modal>
    </Box>
  );
};

export default AddCard;
