import React, { useEffect } from "react";
import { Dropdown, Menu, Typography, Button, notification } from "antd";
import { FiChevronDown } from "react-icons/fi";
import { GoDatabase } from "react-icons/go";
import { useAtom, useAtomValue } from "jotai";

import { useReactFlow } from "@xyflow/react";

import CanvasCard from "../../common/CanvasCard";
import AddCard from "../../AddCard/AddCard";
import { dataApi } from "../../../services/apiHelpers";
import { availableDbs, selectedDatabaseAtom, uid } from "../../../state/state";
import stepsCategories from "../../../constants/categories";
import { nodeTitleMap } from "../../../constants/nodeTitles";

const { Text } = Typography;

const SelectDatabase = () => {
  const { addNodes } = useReactFlow();

  const [selectedDatabase, setSelectedDatabase] = useAtom(selectedDatabaseAtom);
  const [availableDatabases, setAvailableDatabases] = useAtom(availableDbs);
  const userId = useAtomValue(uid);

  const populateDbList = async () => {
    try {
      const res = await dataApi.post("/list_db", { uid: userId });
      const dbs = Object.values(res.data).map((db) => ({
        db_id: db.db_id,
        db_name: db.db_name || db.db_id,
      }));
      setAvailableDatabases(dbs);
    } catch (e) {
      notification.error({
        message: "Could not fetch database list",
        description: "Something went wrong",
      });
    }
  };

  useEffect(() => {
    populateDbList();
  }, []);

  const handleDatabaseChange = ({ key }) => {
    const selectedDb = availableDatabases.find((db) => db.db_id === key);
    setSelectedDatabase(selectedDb);
  };

  const dropdownMenu = (
    <Menu onClick={handleDatabaseChange}>
      {availableDatabases.map(({ db_id, db_name }) => (
        <Menu.Item key={db_id} className="dropdown-menu-item">
          {db_name}
        </Menu.Item>
      ))}
    </Menu>
  );

  const handleSelectDatabase = () => {
    if (selectedDatabase) {
      notification.success({
        message: "Database Selected",
        description: `You have selected the ${selectedDatabase.db_name} database.`,
      });
    } else {
      notification.error({
        message: "No Database Selected",
        description: "Please select a database before proceeding.",
      });
    }
  };

  // Options for adding the next node
  const nextNodeOptions = stepsCategories
    .find((category) => category.category === "Knowledge")
    ?.steps.filter(
      (step) =>
        step.name === "Knowledge Retrieving" ||
        step.name === "Hybrid Retrieval Configuration"
    );

  // Function to add a new node to the flow
  const onAddNode = (nodeData) => {
    const nodeType = nodeTitleMap.get(nodeData.name) || "default";
    const newNode = {
      id: `${nodeData.id}-${Date.now()}`,
      type: nodeType,
      position: { x: 250, y: 150 },
      data: { label: nodeData.name },
    };
    // addNodes(newNode);
  };

  return (
    <CanvasCard
      title="Select Database"
      headerIcon={<GoDatabase className="text-white text-2xl" />}
      className="your-classname"
    >
      <div className="flex flex-col gap-4">
        <div className="input-section">
          <div className="option-label">Database</div>
          <Dropdown overlay={dropdownMenu} trigger={["click"]}>
            <div className="dropdown-button">
              <span>{selectedDatabase?.db_name || "Select a database"}</span>
              <FiChevronDown className="dropdown-icon" />
            </div>
          </Dropdown>
          <Text className="help-text">
            Select a database from the predefined options.
          </Text>
        </div>
        <div className="flex justify-center items-center">
          <Button
            style={{ marginTop: "20px" }}
            type="primary"
            onClick={handleSelectDatabase}
          >
            Select Database
          </Button>
        </div>
      </div>
    </CanvasCard>
  );
};

export default SelectDatabase;
