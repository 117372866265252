import React, { useState } from "react";
import { InputNumber, Dropdown, Menu, Checkbox, Typography, Tabs } from "antd";
import { FiChevronDown } from "react-icons/fi";
import { BsGear } from "react-icons/bs";
import CanvasCard from "../../common/CanvasCard";

const { Text } = Typography;
const { TabPane } = Tabs;

const HybridRetrievalConfiguration = () => {
  const [loading, setLoading] = useState(false);
  const [weightingStrategy, setWeightingStrategy] = useState({
    vectorWeight: 0.5,
    graphWeight: 0.5,
    normalization: "min-max",
    scoreCombination: "sum",
  });
  const [concatenationStrategy, setConcatenationStrategy] = useState({
    order: "vector-first",
    includeDuplicates: false,
  });

  const normalizationMenu = (
    <Menu
      onClick={(e) =>
        setWeightingStrategy({
          ...weightingStrategy,
          normalization: e.key,
        })
      }
    >
      <Menu.Item key="min-max">Min-Max</Menu.Item>
      <Menu.Item key="z-score">Z-Score</Menu.Item>
    </Menu>
  );

  const scoreCombinationMenu = (
    <Menu
      onClick={(e) =>
        setWeightingStrategy({
          ...weightingStrategy,
          scoreCombination: e.key,
        })
      }
    >
      <Menu.Item key="sum">Sum</Menu.Item>
      <Menu.Item key="average">Average</Menu.Item>
    </Menu>
  );

  const orderMenu = (
    <Menu
      onClick={(e) =>
        setConcatenationStrategy({
          ...concatenationStrategy,
          order: e.key,
        })
      }
    >
      <Menu.Item key="vector-first">Vector-First</Menu.Item>
      <Menu.Item key="knowledge-graph-first">Knowledge Graph-First</Menu.Item>
      <Menu.Item key="random">Random</Menu.Item>
    </Menu>
  );

  return (
    <CanvasCard
      title="Hybrid Retrieval Configuration"
      headerIcon={<BsGear className="text-white text-2xl" />}
      className="preprocessing-card"
    >
      <Tabs defaultActiveKey="1">
        <TabPane tab="Weighting Strategy" key="1">
          <div className="section">
            <Text className="section-title">Vector Database Weight</Text>
            <InputNumber
              min={0}
              max={1}
              step={0.1}
              value={weightingStrategy.vectorWeight}
              onChange={(value) =>
                setWeightingStrategy({
                  ...weightingStrategy,
                  vectorWeight: value,
                })
              }
              className="input-field"
            />
          </div>
          <div className="section">
            <Text className="section-title">Knowledge Graph Weight</Text>
            <InputNumber
              min={0}
              max={1}
              step={0.1}
              value={weightingStrategy.graphWeight}
              onChange={(value) =>
                setWeightingStrategy({
                  ...weightingStrategy,
                  graphWeight: value,
                })
              }
              className="input-field"
            />
          </div>
          <div className="section">
            <Text className="section-title">Normalization Method</Text>
            <Dropdown overlay={normalizationMenu} trigger={["click"]}>
              <div className="dropdown-button">
                <span>{weightingStrategy.normalization}</span>
                <FiChevronDown className="dropdown-icon" />
              </div>
            </Dropdown>
          </div>
          <div className="section">
            <Text className="section-title">Score Combination Method</Text>
            <Dropdown overlay={scoreCombinationMenu} trigger={["click"]}>
              <div className="dropdown-button">
                <span>{weightingStrategy.scoreCombination}</span>
                <FiChevronDown className="dropdown-icon" />
              </div>
            </Dropdown>
          </div>
        </TabPane>

        <TabPane tab="Concatenation Strategy" key="2">
          <div className="section">
            <Text className="section-title">Order of Results</Text>
            <Dropdown overlay={orderMenu} trigger={["click"]}>
              <div className="dropdown-button">
                <span>{concatenationStrategy.order}</span>
                <FiChevronDown className="dropdown-icon" />
              </div>
            </Dropdown>
          </div>
          <div className="section">
            <Text className="section-title">Include Duplicates</Text>
            &nbsp;
            <Checkbox
              checked={concatenationStrategy.includeDuplicates}
              onChange={(e) =>
                setConcatenationStrategy({
                  ...concatenationStrategy,
                  includeDuplicates: e.target.checked,
                })
              }
              className="checkbox-field"
            />
          </div>
        </TabPane>
      </Tabs>
    </CanvasCard>
  );
};

export default HybridRetrievalConfiguration;
