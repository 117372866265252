import React from "react";
import { Navigate } from "react-router-dom";
import { useAtomValue } from "jotai";
import { userAtom } from "../../state/state";

const ProtectedRoute = ({ children }) => {
  const user = useAtomValue(userAtom);

  return !user ? <Navigate to="/" replace /> : children;
};

export default ProtectedRoute;
